import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { DeliveryBookType } from '../config/types';
import lib from '../lib/library';
import CheckBox from './CheckBox';
import Divider from './Divider';
import { Thumbnail } from './Thumbnail';

interface Props {
  data: DeliveryBookType;
  showCheckBox?: boolean;
  handleChecked: (index: number | undefined, e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  index?: number;
  checked?: boolean;
  showBuyInfo?: boolean;
  disableDetail?: boolean;
}

const DeliveryBook = (props: Props) => {
  const {
    book_id,
    author_name,
    book_name,
    book_sub_name,
    price,
    publisher_name,
    discount_rate,
    book_condition,
    imgUrl
  } = props.data;

  const history = useHistory();

  const { showCheckBox, handleChecked, index, showBuyInfo, checked, disableDetail } = props;

  const bookTitle = useMemo(() => {
    switch (book_condition) {
      case 0:
        return book_name;
      case 1:
        return `[중고 S] ${book_name}`;
      case 2:
        return `[중고 A+] ${book_name}`;
      case 3:
        return `[중고 A] ${book_name}`;
      default:
        return book_name;
    }
  }, [book_condition, book_name]);

  const handleDetail = useCallback(() => {
    if (!disableDetail) {
      history.push(`/detail/${book_id}?book_condition=${book_condition}`);
    }
  }, [disableDetail, book_id, book_condition, history]);

  const realPrice = useMemo(() => {
    return lib.numberCommaFormat(price) + "원";
  }, [price]);

  const discountPrice = useMemo(() => {
    return lib.numberCommaFormat(price * (1 - discount_rate)) + "원";
  }, [price, discount_rate]);

  const mileage = useMemo(() => {
    return lib.numberCommaFormat(price * 0.05);
  }, [price]);

  return (
    <>
      <Wrapper>
        <Thumbnail src={imgUrl} onClick={handleDetail} />
        <BookDetail>
          <BookTitle onClick={handleDetail}>{bookTitle}</BookTitle>
          <BookSubTitle>{book_sub_name}</BookSubTitle>
          <ConditionalView visible={!showBuyInfo}>
            <BookAdditional>
              {author_name} | {publisher_name}
            </BookAdditional>
          </ConditionalView>

          <ConditionalView visible={showBuyInfo}>
            <BookAdditional>
              <LineThroughText>{realPrice}</LineThroughText>
              <Price>{discountPrice}</Price>
            </BookAdditional>

            <BookAdditional>
              마일리지 <Price>{mileage}</Price>점 적립
            </BookAdditional>
          </ConditionalView>
          {showCheckBox && (
            <CheckBox
              checked={checked}
              handleChecked={(e) => handleChecked(index, e)}
              style={{ position: "absolute", right: 0, bottom: "25px" }}
              defaultChecked
            />
          )}
        </BookDetail>
      </Wrapper>
      <Divider />
    </>
  );
};

DeliveryBook.defaultProps = {
  handleChecked: () => {}
};

const Wrapper = styled("div")`
  display: flex;
  width: 100%;
  min-height: 132px;
`;

const BookDetail = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  position: relative;
  padding-left: 1rem;
  flex: 9;
`;

const BookTitle = styled("p")`
  margin: 0;
  padding-top: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  word-break: keep-all;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const BookSubTitle = styled("p")`
  margin: 0;
  font-size: 14px;
  color: #888;
`;

const BookAdditional = styled("p")`
  margin: 0;
  color: #999999;
  font-size: 13px;
  padding-right: 5px;
`;

const LineThroughText = styled("span")`
  text-decoration: line-through;
  padding-right: 5px;
`;

const ConditionalView = styled("div")<{ visible: boolean | undefined }>`
  display: ${(props) => (props.visible ? "block" : "none")};
`;

const Price = styled("span")`
  color: #000;
  font-size: 13px;
  padding-right: 5px;
  font-weight: bold;
`;

export default DeliveryBook;
