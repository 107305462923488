import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/Button';
import FooterInfo from '../components/FooterInfo';
import { Header } from '../components/Header';
import { Input } from '../components/Input';
import Modal from '../components/Modal';
import useInput from '../hooks/useInput';
import useWarning from '../hooks/useWarning';
import lib from '../lib/library';
import UserStore from '../stores/user';

const FindPasswordByPhone: React.FC = () => {
  const history = useHistory();

  const [state, onChange] = useInput({
    name: "",
    email: "",
    phone: "",
    certification_number: "",
    password: "",
    passwordChk: ""
  });

  const [warning, setWarning] = useWarning({
    name: "",
    email: "",
    phone: "",
    certification_number: "",
    password: "",
    passwordChk: ""
  });

  const { name, email, phone, certification_number, password, passwordChk } = state;

  const [token, setToken] = useState("");
  const [opened, setOpened] = useState(false);

  const userStore = useMemo(() => {
    return UserStore.getInstance();
  }, []);

  const closeModal = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const validateName = useCallback(() => {
    if (!name) {
      setWarning({ name: "이름을 입력해주세요." });
      return false;
    } else {
      setWarning({ name: "" });
      return true;
    }
  }, [name, setWarning]);

  const validateEmail = useCallback(() => {
    if (!email) {
      setWarning({ email: "이메일을 입력해주세요." });
      return false;
    } else if (!lib.emailValidate(email)) {
      setWarning({ email: "이메일 양식을 확인해주세요." });
      return false;
    } else {
      setWarning({ email: "" });
      return true;
    }
  }, [email, setWarning]);

  const validatePhone = useCallback(() => {
    if (!phone) {
      setWarning({ phone: "전화번호를 입력해주세요." });
      return false;
    } else if (!lib.phoneValidate(phone)) {
      setWarning({ phone: "전화번호 양식을 확인해주세요." });
      return false;
    } else {
      setWarning({ phone: "" });
      return true;
    }
  }, [phone, setWarning]);

  const validateCertNum = useCallback(() => {
    if (!certification_number) {
      setWarning({ certification_number: "인증번호를 입력해주세요." });
      return false;
    } else {
      setWarning({ certification_number: "" });
      return true;
    }
  }, [certification_number, setWarning]);

  const validatePassword = useCallback(() => {
    if (!password) {
      setWarning({ password: "비밀번호를 입력해주세요." });
      return false;
    } else if (!lib.passwordValidate(password)) {
      setWarning({ password: "비밀번호 양식을 확인해주세요." });
      return false;
    } else {
      setWarning({ password: "" });
      return true;
    }
  }, [password, setWarning]);

  const validatePasswordChk = useCallback(() => {
    if (password !== passwordChk) {
      setWarning({ passwordChk: "비밀번호가 일치하지 않습니다." });
      return false;
    } else {
      setWarning({ passwordChk: "" });
      return true;
    }
  }, [password, passwordChk, setWarning]);

  const sendCertNum = useCallback(async () => {
    const nameValidated = validateName();
    const emailValidated = validateEmail();
    const phoneValidated = validatePhone();

    if (nameValidated && emailValidated && phoneValidated) {
      const response = await userStore.authPhoneForPassword(phone);
      if (response.status === 200) {
        setToken(response.data.token_string);
      }
    }
  }, [phone, userStore, setToken, validateName, validateEmail, validatePhone]);

  const inputDisabled = useMemo(() => {
    if (token) {
      return true;
    } else {
      return false;
    }
  }, [token]);

  const chkCertNum = useCallback(async () => {
    const certNumValidate = validateCertNum();

    if (certNumValidate) {
      const response = await userStore.checkNumberForPassword(email, phone, certification_number, token);
      if (response.status === 200) {
        setOpened(true);
      }
    }
  }, [userStore, email, phone, certification_number, token, validateCertNum]);

  const authForm = useMemo(() => {
    return (
      token && (
        <AuthForm>
          <StyledInput
            name="certification_number"
            label="인증번호"
            placeholder="인증번호 6자리를 입력해주세요."
            onChange={onChange}
            onBlur={validateCertNum}
            warning={warning.certification_number}
          />
          <StyledButton value="인증하기" variant="dark" onClick={chkCertNum} />
        </AuthForm>
      )
    );
  }, [token, onChange, chkCertNum, validateCertNum, warning.certification_number]);

  const changePassword = useCallback(async () => {
    const passwordValidate = validatePassword();
    const passwordChkValidate = validatePasswordChk();

    if (passwordValidate && passwordChkValidate) {
      const response = await userStore.changePassword(email, phone, password);
      if (response.status === 200) {
        history.push("/login");
      }
    }
  }, [userStore, email, phone, password, history, validatePassword, validatePasswordChk]);

  return (
    <Container>
      <Header />
      <Content>
        <Title>비밀번호 찾기</Title>
        <Description>등록된 연락처 인증을 통해 비밀번호를 변경해주세요</Description>
        <StyledInput
          label="이름"
          name="name"
          onChange={onChange}
          onBlur={validateName}
          warning={warning.name}
          disabled={inputDisabled}
        />
        <StyledInput
          label="아이디(이메일)"
          name="email"
          onChange={onChange}
          onBlur={validateEmail}
          warning={warning.email}
          disabled={inputDisabled}
        />
        <StyledInput
          label="휴대전화 번호"
          name="phone"
          onChange={onChange}
          onBlur={validatePhone}
          warning={warning.phone}
          disabled={inputDisabled}
        />
        <StyledButton value="인증번호 발송하기" variant="dark" onClick={sendCertNum} />
        {authForm}
      </Content>
      <FooterInfo />
      <Modal opened={opened} handleClose={closeModal}>
        <PasswordWrapper>
          <StyledInput
            label="새 비밀번호"
            name="password"
            onChange={onChange}
            type="password"
            placeholder="4~15자리 영문, 숫자 포함"
            onBlur={validatePassword}
            warning={warning.password}
          />
          <StyledInput
            label="새 비밀번호 확인"
            name="passwordChk"
            onChange={onChange}
            type="password"
            onBlur={validatePasswordChk}
            warning={warning.passwordChk}
          />
          <StyledButton value="변경하기" variant="dark" onClick={changePassword} />
        </PasswordWrapper>
      </Modal>
    </Container>
  );
};

const Container = styled("div")``;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  max-width: 408px;
  margin: 0 auto;
  min-height: calc(100vh - 58px);
  padding: 64px 0px 128px;

  @media (max-width: 408px) {
    padding: 64px 24px 128px;
  }
`;

const Title = styled("h1")`
  font-size: 32px;
`;

const Description = styled("div")`
  font-size: 14px;
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const AuthForm = styled("div")`
  margin-top: 16px;
`;

const PasswordWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default FindPasswordByPhone;
