import { useReducer } from 'react';

interface State {
  [key: string]: string;
}

function reducer(state: any, action: any) {
  return {
    ...state,
    [Object.keys(action)[0]]: Object.values(action)[0],
  };
}

export default function useWarning(initialForm: State) {
  const [state, dispatch] = useReducer(reducer, initialForm);
  const setState = (state: State) => {
    dispatch(state);
  };
  return [state, setState];
}
