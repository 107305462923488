import axios from 'axios';
import { action, computed, flow, observable } from 'mobx';

import apiServerPath from '../config/config';
import { BasketType } from '../config/types';
import lib from '../lib/library';
import UserStore from './user';
import UtilStore from './util';

axios.defaults.baseURL = apiServerPath;
axios.defaults.withCredentials = true;

type QueryType = {
  type: string | null;
  rank: string | number | null;
};

export default class BasketStore {
  static instance: BasketStore | null = null;

  static getInstance() {
    if (BasketStore.instance === null) {
      BasketStore.instance = new BasketStore();
    }

    return BasketStore.instance;
  }

  @observable
  baskets: Array<BasketType> = [];

  utilStore = UtilStore.getInstance();
  userStore = UserStore.getInstance();

  @action.bound
  init() {
    const initData = JSON.parse(sessionStorage.getItem("books")!);
    this.baskets = initData ? initData : [];
  }

  @action
  autoPutting = flow(function* (this: BasketStore, bookList: string) {
    const response = yield axios.get(`/bibly/api?cmd=book_list&book_list=${bookList}`);

    console.log(response);

    if (response.status === 200) {
      this.init();

      response.data.list?.forEach((element: any) => {
        const data = {
          bookName: element.book_name,
          authorName: element.author_name,
          publisherName: element.publisher_name,
          bookId: element.book_id,
          imagePath: element.image_url,
          book_condition: 0
        };
        this.handleBasket(data, true);
      });
    }
  });

  @action
  handleBasket = flow(function* (this: BasketStore, book: BasketType, hideTollTip?: boolean, query?: QueryType) {
    if (!this.isBookExist(book.bookId)) {
      if (this.count >= 5) {
        this.utilStore.addToolTips(`책을 5권까지만 담아주세요.`);
        return;
      }

      this.baskets = [book, ...this.baskets];

      sessionStorage.setItem("books", JSON.stringify(this.baskets));

      const josa = lib.returnJosa(book.bookName);

      if (!hideTollTip) {
        this.utilStore.addToolTips(`「${book.bookName}」${josa} 담았습니다.`);
      }

      if (this.isLogin) {
        yield axios.post("/bibly/api", {
          cmd: "cart_put",
          cart_list: [{ ...book }]
        });
      }

      window.biblyTrackingSend({
        service: "찾서",
        behavior: "cart",
        extra: JSON.stringify({
          btrListType: query?.type,
          btrRank: query?.rank
        })
      });
    } else {
      this.spliceBasket(book.bookId, book.book_condition);

      if (this.isLogin) {
        yield axios.post("/bibly/api", {
          cmd: "cart_delete",
          cart_list: [
            {
              bookId: book.bookId,
              book_condition: book.book_condition
            }
          ]
        });
      }
    }
  });

  @action.bound
  findBasket(id: number) {
    return this.baskets[id - 1];
  }

  @action.bound
  isBookExist(id: number) {
    return this.baskets.find((el) => el.bookId === id);
  }

  @action
  spliceBasket = flow(function* (this: BasketStore, bookId: number, book_condition: number) {
    this.baskets = this.baskets.filter((el) => el.bookId !== bookId);
    sessionStorage.setItem("books", JSON.stringify(this.baskets));

    if (this.isLogin) {
      yield axios.post("/bibly/api", {
        cmd: "cart_delete",
        cart_list: [
          {
            bookId,
            book_condition
          }
        ]
      });
    }
  });

  // @action.bound
  // spliceBasket(id: number, book_condition: number) {
  //   this.baskets = this.baskets.filter((el) => el.bookId !== id);
  //   sessionStorage.setItem("books", JSON.stringify(this.baskets));

  //   if(this.isLogin){

  //   }
  // }

  @computed
  get count() {
    return this.baskets.length;
  }

  @computed
  get isLogin() {
    return this.userStore.isLogin;
  }
}
