import { inject, observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/Button';
import FooterInfo from '../components/FooterInfo';
import { Header } from '../components/Header';
import { Input } from '../components/Input';
import { ConsentList } from '../components/Register/ConsentList';
import useCheckBox from '../hooks/useCheckBox';
import useInput from '../hooks/useInput';
import useWarning from '../hooks/useWarning';
import lib from '../lib/library';
import RegisterStore from '../stores/register';
import UserStore from '../stores/user';
import UtilStore from '../stores/util';

interface Props {
  register: RegisterStore;
}

const Register: React.FC<Props> = (props) => {
  const { register } = props;

  const history = useHistory();

  const userStore = useMemo(() => {
    return UserStore.getInstance();
  }, []);

  const [state, onChange] = useInput({
    name: "",
    email: "",
    password: "",
    passwordChk: "",
    phone: "",
    certification_number: ""
  });

  const [warning, setWarning] = useWarning({
    name: "",
    email: "",
    password: "",
    passwordChk: "",
    phone: ""
  });

  const [checkBoxState, onChecked] = useCheckBox({
    useAgree: false,
    privacyAgree: false,
    marketingCheckBox: false
  });

  const { name, email, password, passwordChk, phone, certification_number } = state;

  const { useAgree, privacyAgree, marketingCheckBox } = checkBoxState;

  const phoneAuthForm = useMemo(() => {
    return (
      register.messageSented && (
        <StyledInput placeholder="인증번호를 입력해주세요." name="certification_number" onChange={onChange} />
      )
    );
  }, [register.messageSented, onChange]);

  const utilStore = useMemo(() => {
    return UtilStore.getInstance();
  }, []);

  const validateName = useCallback(() => {
    if (!name) {
      setWarning({ name: "이름을 입력해주세요." });
      return false;
    } else {
      setWarning({ name: "" });
      return true;
    }
  }, [name, setWarning]);

  const validateEmail = useCallback(async () => {
    if (!email) {
      setWarning({ email: "이메일을 입력해주세요." });
      return false;
    } else if (!lib.emailValidate(email)) {
      setWarning({ email: "이메일 양식을 확인해주세요." });
      return false;
    } else {
      if (!lib.emailValidate(email)) {
      } else {
        const response = await userStore.validateEmail(email);
        if (response) {
          setWarning({ email: "" });
          return true;
        } else {
          setWarning({ email: "중복된 이메일입니다." });
          return false;
        }
      }
    }
  }, [userStore, email, setWarning]);

  const validatePassword = useCallback(() => {
    if (!password) {
      setWarning({ password: "비밀번호를 입력해주세요." });
      return false;
    } else if (!lib.passwordValidate(password)) {
      setWarning({ password: "비밀번호 양식을 확인해주세요." });
      return false;
    } else {
      setWarning({ password: "" });
      return true;
    }
  }, [password, setWarning]);

  const validatePasswordChk = useCallback(() => {
    if (!password) {
      setWarning({ passwordChk: "비밀번호 확인을 입력해주세요." });
      return false;
    } else if (password !== passwordChk) {
      setWarning({ passwordChk: "비밀번호가 일치하지 않습니다." });
      return false;
    } else {
      setWarning({ passwordChk: "" });
      return true;
    }
  }, [password, passwordChk, setWarning]);

  const validatePhone = useCallback(() => {
    if (!phone) {
      setWarning({ phone: "전화번호를 입력해주세요." });
      return false;
    } else if (!lib.phoneValidate(phone)) {
      setWarning({ phone: "전화번호 양식을 확인해주세요." });
      return false;
    } else {
      setWarning({ phone: "" });
      return true;
    }
  }, [phone, setWarning]);

  const authNumber = useCallback(() => {
    const phoneValidate = validatePhone();

    if (phoneValidate) {
      register.sendCertification(phone);
    }
  }, [register, phone, validatePhone]);

  const handleRegister = useCallback(async () => {
    const emailValidate = validateEmail();
    const passwordValidate = validatePassword();
    const passwordChkValidate = validatePasswordChk();
    const nameValidate = validateName();
    const phoneValidate = validatePhone();

    if (emailValidate && passwordValidate && passwordChkValidate && nameValidate && phoneValidate) {
      const data = {
        user_email: email,
        user_name: name,
        user_phone: phone,
        user_password: password,
        certification_number,
        marketing_yn: marketingCheckBox ? 1 : 0
      };

      if (!useAgree) {
        utilStore.addToolTips("이용약관에 동의해주세요.");
      } else if (!privacyAgree) {
        utilStore.addToolTips("개인정보처리방침에 동의해주세요.");
      } else {
        const response = await register.register(data);
        if (response.status === 200) {
          history.push("/");
        }
      }
    }
  }, [
    email,
    name,
    phone,
    password,
    certification_number,
    register,
    validateEmail,
    validatePassword,
    validatePasswordChk,
    validateName,
    validatePhone,
    useAgree,
    privacyAgree,
    utilStore,
    history,
    marketingCheckBox
  ]);

  return (
    <Container>
      <Header />
      <Content>
        <InputWrapper>
          <StyledInput label="이메일" name="email" onBlur={validateEmail} warning={warning.email} onChange={onChange} />
          <StyledInput
            type="password"
            label="비밀번호 (4~15자리 영문, 숫자 포함)"
            name="password"
            onBlur={validatePassword}
            warning={warning.password}
            onChange={onChange}
          />
          <StyledInput
            type="password"
            label="비밀번호 확인"
            name="passwordChk"
            onBlur={validatePasswordChk}
            warning={warning.passwordChk}
            onChange={onChange}
          />
          <StyledInput label="이름" name="name" onBlur={validateName} warning={warning.name} onChange={onChange} />
          <PhoneWrapper>
            <StyledInput
              style={{ flex: 4 }}
              label="휴대전화 번호"
              name="phone"
              onBlur={validatePhone}
              warning={warning.phone}
              placeholder="-를 제외한 휴대전화 번호를 입력해주세요"
              onChange={onChange}
            />
            <AuthButton onClick={authNumber} style={{ flex: 1, marginLeft: 12 }} value="인증" />
          </PhoneWrapper>
          {phoneAuthForm}
        </InputWrapper>
        <ConsentList onChecked={onChecked} />
        <LoginBtn value="회원가입" variant="dark" onClick={handleRegister} />
      </Content>
      <FooterInfo />
    </Container>
  );
};

const Container = styled("div")``;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 0px 24px;
  max-width: 408px;
  margin: 0 auto;
  min-height: 100vh;
`;

const InputWrapper = styled("div")`
  width: 100%;
  margin-top: 64px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 16px;
`;

const LoginBtn = styled(Button)`
  margin-top: 18px;
  width: 100%;
`;

const PhoneWrapper = styled("div")`
  display: flex;
`;

const AuthButton = styled(Button)`
  height: 43px;
  margin-top: 24px;
`;

export default inject("register")(observer(Register));
