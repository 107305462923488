import { Provider } from 'mobx-react';
import React from 'react';
import { CookiesProvider } from 'react-cookie';

import Spinner from './components/Spinner';
import ToolTip from './components/ToolTip';
import TopButton from './components/TopButton';
import App from './containers/App';
import stores from './stores';

const Root: React.FC = () => (
  <Provider {...stores}>
    <CookiesProvider>
      <App />
      <ToolTip />
      <Spinner />
      <TopButton />
    </CookiesProvider>
  </Provider>
);

export default Root;
