import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Input } from '../Input';

interface Props {}

export const SearchInput: React.FC<Props> = (props) => {
  const { ...restProps } = props;

  const history = useHistory();

  const handleSearch = useCallback(
    (bookName: string) => {
      history.push(`/search/${encodeURIComponent(bookName)}`);
    },
    [history]
  );

  return <SearchForm onSubmit={handleSearch} placeholder="제목, 출판사 또는 저자" type="search" {...restProps} />;
};

const SearchForm = styled(Input)`
  margin-top: 24px;
`;

export default SearchInput;
