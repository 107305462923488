import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CancelType } from '../../../config/types';
import useInput from '../../../hooks/useInput';
import { Button } from '../../Button';
import { Input } from '../../Input';
import Modal from '../../Modal';
import TextButton from '../../TextButton';

interface Props {
  onCancel: (data: CancelType) => void;
}

const DATA = [
  "서비스가 궁금해서 한번 테스트 해봤어요",
  "서비스가 복잡하고 이해하기 어려워서요",
  "온라인으로 내용을 읽어볼 수 있는 줄 알았는데 아니라서요",
  "받아보고 ‘리턴‘해야하고 하는 과정이 귀찮을거 같아서요",
  "5일 동안, 주문한 도서를 다 확인할 자신이 없어서요",
  "기타"
];

export const CancelForm: React.FC<Props> = (props) => {
  const { onCancel } = props;

  const [opened, setOpened] = useState(false);
  const [reason, setReason] = useState<number[]>([]);

  const [state, onChange] = useInput({
    value: ""
  });

  const showModal = useCallback(() => {
    setOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpened(false);
  }, []);

  const handleSelected = useCallback(
    (index: number) => {
      if (reason.includes(index)) {
        setReason(reason.filter((el) => el !== index));
      } else {
        setReason([...reason, index]);
      }
    },
    [reason]
  );

  const isSelected = useCallback(
    (index: number) => {
      return reason.includes(index + 1);
    },
    [reason]
  );

  const handleCancel = useCallback(() => {
    const data = {
      reason: reason
        .filter((el) => el !== 6)
        .sort()
        .toString(),
      reason_direct: reason.includes(6) ? state.value : ""
    };
    onCancel(data);
    closeModal();
    window.scrollTo(0, 0);
  }, [reason, onCancel, state.value, closeModal]);

  const detailInput = useMemo(() => {
    return reason.includes(6) && <ReasonDirectInput value={state.value} name="value" onChange={onChange} />;
  }, [reason, onChange, state.value]);

  const data = useMemo(() => {
    return DATA.map((el, index) => {
      return index === 5 ? (
        <ReasonDirectWrapper>
          <Item
            style={{ display: "inline-block" }}
            key={el}
            onClick={() => handleSelected(index + 1)}
            selected={isSelected(index)}
          >
            {el}
          </Item>
          {detailInput}
        </ReasonDirectWrapper>
      ) : (
        <Item key={el} onClick={() => handleSelected(index + 1)} selected={isSelected(index)}>
          {el}
        </Item>
      );
    });
  }, [handleSelected, isSelected, detailInput]);

  return (
    <>
      <CancelButton
        text="주문 취소하기"
        isBold={false}
        fontSize={12}
        color="#ced4da"
        onClick={showModal}
        showIcon={false}
      />
      <Modal
        opened={opened}
        title="주문 취소하신 이유를 알려주세요. (중복 선택 가능)"
        footer={<Button value="완료" onClick={handleCancel} />}
        handleClose={closeModal}
      >
        <Wrapper>{data}</Wrapper>
      </Modal>
    </>
  );
};

const Wrapper = styled("div")``;

const ReasonDirectWrapper = styled("div")`
  display: flex;
`;

const ReasonDirectInput = styled(Input)`
  margin-left: 16px;
`;

const Item = styled("div")<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? "#212529" : "#f1f3f5")};
  color: ${(props) => (props.selected ? "#fff" : "#000")};
  padding: 12px;
  border-radius: 18px;
  cursor: pointer;
  word-break: keep-all;
  font-size: 14px;

  & + div {
    margin-top: 8px;
  }
`;

const CancelButton = styled(TextButton)`
  padding: 0;
`;
