import { action, observable } from 'mobx';

export default class UtilStore {
  static instance: UtilStore | null = null;

  static getInstance() {
    if (UtilStore.instance === null) {
      UtilStore.instance = new UtilStore();
    }
    return UtilStore.instance;
  }

  @observable
  tooltips: Array<string> = [];

  @observable
  fetching = false;

  @action
  addToolTips = (text: string) => {
    this.tooltips = [...this.tooltips, text];
    setTimeout(() => {
      this.tooltips = this.tooltips.slice(1, this.tooltips.length);
    }, 3000);
  };

  @action
  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };
}
