import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

interface Props {
  opened: boolean;
  onClick: () => void;
}

export const Drawer: React.FC<Props> = (props) => {
  const { opened, onClick, children } = props;

  const handleChildClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [opened]);

  return (
    <Wrapper onClick={onClick} opened={opened}>
      <Content onClick={handleChildClick} opened={opened}>
        {children}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ opened: boolean }>`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  visibility: ${(props) => (props.opened ? "visible" : "hidden")};
  opacity: ${(props) => (props.opened ? "1" : "0")};
  transition: 0.3s ease-in-out;
`;

const Content = styled("div")<{ opened: boolean }>`
  background-color: #fff;
  width: 300px;
  height: 100vh;
  transition: 0.3s ease-in-out;
  left: ${(props) => (props.opened ? "-0px" : "-300px")};
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (max-width: 420px) {
    width: 250px;
  }
`;
