import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Button } from '../components/Button';
import FooterInfo from '../components/FooterInfo';
import { Header } from '../components/Header';
import { Input } from '../components/Input';
import useInput from '../hooks/useInput';
import useWarning from '../hooks/useWarning';
import lib from '../lib/library';
import UserStore from '../stores/user';

const FindPasswordByEmail = () => {
  const [state, onChange] = useInput({
    email: ""
  });

  const [warning, setWarning] = useWarning({
    email: ""
  });

  const { email } = state;

  const userStore = useMemo(() => {
    return UserStore.getInstance();
  }, []);

  const validateEmail = useCallback(() => {
    if (!email) {
      setWarning({ email: "이메일을 입력해주세요." });
      return false;
    } else if (!lib.emailValidate(email)) {
      setWarning({ email: "이메일 양식을 확인해주세요." });
      return false;
    } else {
      setWarning({ email: "" });
      return true;
    }
  }, [email, setWarning]);

  const sendEmail = useCallback(() => {
    const emailValidate = validateEmail();

    if (emailValidate) {
      userStore.sendEmailForPassword(email);
    }
  }, [userStore, email, validateEmail]);

  return (
    <Container>
      <Header />
      <Content>
        <Title>비밀번호 찾기</Title>
        <Description>등록된 이메일을 통해 비밀번호를 재설정 할 수 있는 링크를 드릴게요</Description>
        <StyledInput
          label="이메일"
          name="email"
          onChange={onChange}
          onBlur={validateEmail}
          warning={warning.email}
          onSubmit={sendEmail}
        />
        <StyledButton value="인증 메일 보내기" variant="dark" onClick={sendEmail} />
      </Content>
      <FooterInfo />
    </Container>
  );
};

const Container = styled("div")``;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  max-width: 408px;
  margin: 0 auto;
  min-height: calc(100vh - 58px);
  padding: 64px 0px 128px;

  @media (max-width: 408px) {
    padding: 64px 24px 128px;
  }
`;

const Title = styled("h1")`
  font-size: 32px;
`;

const Description = styled("div")`
  font-size: 14px;
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default FindPasswordByEmail;
