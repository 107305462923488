import { useEffect } from 'react';

const ScrollToTop = (props: any) => {
  const { location, children } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

export default ScrollToTop;
