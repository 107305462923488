import { useReducer } from 'react';

interface State {
  [key: string]: boolean;
}

function reducer(state: any, action: any) {
  return {
    ...state,
    [action.name]: action.checked
  };
}

export default function useCheckBox(initialForm: State) {
  const [state, dispatch] = useReducer(reducer, initialForm);
  const onChange = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    dispatch(e.target);
  };

  return [state, onChange];
}
