import React, { useCallback, useMemo, useState } from 'react';
import DaumPostCode, { AddressData } from 'react-daum-postcode';
import styled from 'styled-components';

interface Props {
  opener: React.ReactElement<{ onClick: () => void }>;
  onAddress: (data: AddressData) => void;
}

export const PostCode: React.FC<Props> = (props) => {
  const { opener, onAddress } = props;
  const [show, setShow] = useState(false);

  const showModal = useCallback(() => {
    setShow(true);
  }, []);

  const closeModal = useCallback(() => {
    setShow(false);
  }, []);

  const clonedOpener = useMemo(() => {
    return React.cloneElement(opener, {
      onClick: showModal
    });
  }, [opener, showModal]);

  const handleChildClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  const handleAddress = useCallback(
    (data: AddressData) => {
      closeModal();
      onAddress(data);
    },
    [onAddress, closeModal]
  );

  return (
    <>
      {clonedOpener}
      <Wrapper onClick={closeModal} show={show}>
        <Content onClick={handleChildClick}>
          <Header>
            우편번호 검색
            <HeaderBtn onClick={closeModal}>X</HeaderBtn>
          </Header>
          <AddressModule style={{ position: "relative" }} height="100%" width="100%" onComplete={handleAddress} />
        </Content>
      </Wrapper>
    </>
  );
};

const Wrapper = styled("div")<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  opacity: ${(props) => (props.show ? "1" : "0")};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transition: 0.25s;
`;

const Header = styled("header")`
  display: none;
  align-items: center;
  height: 50px;
  justify-content: center;
  background-color: #212529;
  color: #fff;

  @media (max-width: 767px) {
    display: flex;
  }
`;

const HeaderBtn = styled("span")`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`;

const Content = styled("div")`
  width: 480px;
  height: 640px;
  position: relative;

  @media (max-width: 767px) {
    width: 100vw;
    height: 100vh;
  }
`;

const AddressModule = styled(DaumPostCode)``;
