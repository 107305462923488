import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Prompt } from '../../../components/Prompt';
import { DeliveryBookType } from '../../../config/types';
import useInput from '../../../hooks/useInput';
import lib from '../../../lib/library';
import DeliveryStore from '../../../stores/delivery';
import DeliveryBook from '../../DeliveryBook';
import { Input } from '../../Input';
import TextButton from '../../TextButton';

interface Props {
  bookList: DeliveryBookType[];
  delivery?: DeliveryStore;
  receiver: any;
  purchaseId: string;
  cancelBuyForm: () => void;
}

const PurchaseForm: React.FC<Props> = (props) => {
  const history = useHistory();

  const { bookList, delivery, receiver, purchaseId, cancelBuyForm } = props;
  const [returnBookList, setReturnBookList] = useState<DeliveryBookType[]>([]);
  const [purchaseBookList, setPurchaseBookList] = useState<DeliveryBookType[]>([]);

  const [state, onChange] = useInput({
    mileage: ""
  });

  const handleChecked = useCallback(
    (index: number | undefined, e: any) => {
      bookList![index!].isChecked = e.target.checked;
      setReturnBookList(bookList?.filter((el) => el.isChecked === false));
      setPurchaseBookList(bookList?.filter((el) => el.isChecked !== false));
    },
    [bookList]
  );

  const bookContents = useMemo(() => {
    return bookList?.map((el, index) => (
      <DeliveryBook
        checked={el.isChecked}
        handleChecked={handleChecked}
        showCheckBox
        index={index}
        key={el.book_id}
        data={el}
        showBuyInfo
        disableDetail
      />
    ));
  }, [bookList, handleChecked]);

  const buttonText = useMemo(() => {
    return purchaseBookList.length ? "구매할래요" : "모두 리턴할래요";
  }, [purchaseBookList]);

  const purchasePrice = useMemo(() => {
    return purchaseBookList.reduce((prev, next) => prev + next!.price * (1 - next!.discount_rate), 0);
  }, [purchaseBookList]);

  const serviceFee = useMemo(() => {
    return purchaseBookList.length ? 0 : 3000;
  }, [purchaseBookList]);

  useEffect(() => {
    setPurchaseBookList(bookList);
  }, [bookList]);

  const promptSubTitle = useMemo(() => {
    return purchaseBookList.length ? "" : "전부 리턴할 시, 서비스 Fee 3000원이 부과됩니다.";
  }, [purchaseBookList]);

  const mileage = useMemo(() => {
    if (!state.mileage) {
      return 0;
    } else {
      if (state.mileage <= receiver.mileage) {
        return state.mileage;
      } else {
        return receiver.mileage;
      }
    }
  }, [state.mileage, receiver.mileage]);

  const promptTitle = useMemo(() => {
    if (purchaseBookList.length) {
      return `총 ${purchaseBookList.length}권, 결제금액 ${lib.numberCommaFormat(
        purchasePrice - mileage
      )}원 \n 결제하시겠습니까?`;
    } else {
      return "모두 리턴하시겠습니까?";
    }
  }, [purchaseBookList, purchasePrice, mileage]);

  const handleSubmit = useCallback(async () => {
    const returnData = {
      bookList: JSON.stringify(returnBookList),
      receiver_name: receiver.receiver_name,
      receiver_phone: lib.numberAddHypen(receiver.shipping_phone),
      receiver_road: `${receiver.address1} ${receiver.address2}`,
      use_mileage: parseInt(mileage)
    };

    const response = await delivery?.returnBook(returnData, purchaseId);
    if (response.status === 200) {
      await delivery?.fetchDelivery(purchaseId!);
      cancelBuyForm();
      history.push(`/delivery/${purchaseId}?btrType=pd`);
    }
  }, [delivery, purchaseId, receiver, returnBookList, cancelBuyForm, mileage, history]);

  return (
    <Wrapper>
      <WarningWrapper>
        <Warning>받아보신 책들 중 구매하실 책들을 골라주세요.</Warning>
        <Warning>구매를 원하시지 않는 책들은 리턴됩니다.</Warning>
        <Warning>모든 책을 리턴하시는 경우 서비스피 3,000원이 결제됩니다.</Warning>
      </WarningWrapper>
      <SelectBookWrapper>{bookContents}</SelectBookWrapper>

      <Title>결제 금액</Title>

      <PriceInformation>
        <PriceInfo>
          <Description>총 상품가격</Description>
          <Price>{lib.numberCommaFormat(purchasePrice)}원</Price>
        </PriceInfo>

        <PriceInfo>
          <Description>서비스 Fee</Description>
          <Description>
            + <Price>{lib.numberCommaFormat(serviceFee)}원</Price>
          </Description>
        </PriceInfo>

        <MileageInfo>
          <Description>마일리지</Description>
          <MileageInputWrapper>
            <span>- </span>
            <MileageInput
              type="number"
              name="mileage"
              pattern="[0-9]*"
              value={mileage}
              onChange={onChange}
              inputStyle={{ height: 24 }}
            />
            <span> 원</span>
          </MileageInputWrapper>
        </MileageInfo>

        <MileageBalance>잔액: {receiver.mileage}원</MileageBalance>

        <Divider />

        <PriceInfo>
          <Description>결제금액</Description>
          <TotalPrice>{lib.numberCommaFormat(purchasePrice + serviceFee - mileage)}원</TotalPrice>
        </PriceInfo>
      </PriceInformation>

      <ButtonWrapper>
        <TextButton text="뒤로 가기" onClick={cancelBuyForm} showIcon={false} />

        <Prompt
          opener={<TextButton text={buttonText} />}
          title={promptTitle}
          subTitle={promptSubTitle}
          onSuccess={handleSubmit}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default inject("delivery")(observer(PurchaseForm));

const Wrapper = styled("div")`
  padding: 1.5rem;
  position: relative;
  min-height: 70vh;
  padding-bottom: 100px;
`;

const WarningWrapper = styled("div")`
  padding: 0px 24px;
`;

const Warning = styled("li")`
  font-size: 13px;
  color: #adb5bd;

  & + li {
    margin-top: 3px;
  }
`;

const SelectBookWrapper = styled("div")`
  margin-top: 18px;
`;

const PriceInfo = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`;

const PriceInformation = styled("div")`
  padding: 0px 12px;
`;

const Price = styled("span")`
  font-size: 18px;
  font-weight: bold;
  color: #000;
`;

const TotalPrice = styled(Price)`
  color: #e8590c;
`;

const Description = styled("p")`
  color: #ccc;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  position: absolute;
  bottom: 30px;
  width: 100%;
  margin: 0 -1.5rem;
  height: 65px;
`;

const Divider = styled("div")`
  border-top: 1px solid #f1f3f5;
  margin: 12px 0px;
`;

const Title = styled("h2")`
  padding-top: 20px;
`;

const MileageInputWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const MileageInput = styled(Input)`
  width: 60%;
  height: 24px;
  width: 128px;
`;

const MileageInfo = styled("div")`
  display: flex;
  height: 32px;
  align-items: center;
`;

const MileageBalance = styled("p")`
  text-align: right;

  margin-top: 12px;
  font-size: 14px;
`;
