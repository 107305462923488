function getJosa(str: string) {
  const lastStr = str.charCodeAt(str.length - 1) - 44032;
  if (lastStr % 28 === 0) return `${str}를`;
  else return `${str}을`;
}

function returnJosa(str: string) {
  const lastStr = str.charCodeAt(str.length - 1) - 44032;
  if (lastStr % 28 === 0) return "를";
  else return "을";
}

function numberCommaFormat(x: number | undefined) {
  const numberCommasReg = /\B(?=(\d{3})+(?!\d))/g;

  if (!x) return 0;
  return x.toString().replace(numberCommasReg, ",");
}

function isInputElement(element: Element | null): element is HTMLInputElement {
  return element?.nodeName === "INPUT";
}

function numberAddHypen(num: string) {
  return num
    .replace(/[^0-9]/g, "")
    .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/, "$1-$2-$3")
    .replace("--", "-");
}

function emailValidate(email: string) {
  const regExp = /^[0-9a-zA-Z]([-_]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regExp.test(email);
}

function phoneValidate(phone: string) {
  const phoneRule = /^[0-9]{10,11}$/; //전화번호 정규식
  return phoneRule.test(phone);
}

function passwordValidate(password: string) {
  const passwordRule = /^(?=.*[a-zA-Z])(?=.*[0-9]).{4,15}$/;
  return passwordRule.test(password);
}

function cardNumberValidate(cardNumber: string) {
  const cardNumberRule = /^[0-9]{15,16}$/;
  return cardNumberRule.test(cardNumber);
}

function cardDateValidate(date: string) {
  const cardDateRule = /^[0-9]{2}/;
  return cardDateRule.test(date);
}

function cardPrivacyValidate(privacy: string) {
  const privacyRule = /^[0-9]{6}$|^[0-9]{10}$/;
  return privacyRule.test(privacy);
}

export default {
  getJosa,
  numberCommaFormat,
  isInputElement,
  numberAddHypen,
  returnJosa,
  emailValidate,
  phoneValidate,
  passwordValidate,
  cardNumberValidate,
  cardDateValidate,
  cardPrivacyValidate
};
