import { action, flow, observable } from 'mobx';

import Axios from '../axios';
import UserStore from './user';
import UtilStore from './util';

type RegisterType = {
  user_email: string;
  user_name: string;
  user_phone: string;
  user_password: string;
  certification_number: string;
  marketing_yn: number;
};

export default class Register {
  @observable
  messageSented = false;

  @observable
  token = "";

  utilStore = UtilStore.getInstance();
  userStore = UserStore.getInstance();

  @action
  sendCertification = flow(function* (this: Register, user_phone: string) {
    const response = yield Axios.get(`/bibly/api?cmd=sign_up_2_send_token&user_phone=${user_phone}`);

    if (response.status === 200) {
      if (response.data.success) {
        this.token = response.data.token_string;
        this.messageSented = true;
      }
    }
  });

  @action
  register = flow(function* (this: Register, data: RegisterType) {
    this.utilStore.setFetching(true);
    const response = yield Axios.get(
      `/bibly/api?cmd=sign_up_3_complete&email=${data.user_email}&user_name=${data.user_name}&user_phone=${data.user_phone}&user_password=${data.user_password}&token=${this.token}&certification_number=${data.certification_number}&marketing_yn=${data.marketing_yn}`
    );
    this.utilStore.setFetching(false);
    if (response.status === 200) {
      yield this.userStore.login(data.user_email, data.user_password);
    }
    return response;
  });
}
