import React from 'react';
import styled from 'styled-components';

interface Props {
  value?: string;
  onClick?: () => void;
  variant?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const Button = (props: Props) => {
  const { value, variant, onClick, disabled, style, ...restProps } = props;
  return (
    <Wrapper style={style} onClick={onClick} variant={variant} {...restProps} disabled={disabled}>
      {value}
    </Wrapper>
  );
};

const Wrapper = styled("button")<{ disabled: boolean | undefined; variant: string | undefined }>`
  color: ${(props) => {
    if (props.disabled) {
      return "#aaa";
    } else if (props.variant === "dark") {
      return "#fff";
    } else {
      return "#000";
    }
  }};

  background-color: ${(props) => {
    if (props.disabled) {
      return "#eee";
    } else {
      if (props.variant === "dark") {
        return "#000";
      } else {
        return "#f1f3f5";
      }
    }
  }};

  outline: none;
  font-weight: bold;
  border: none;
  padding: 0px 18px;
  font-size: 14px;
  border-radius: 3px;
  height: 48px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    opacity: 0.85;
  }
`;
