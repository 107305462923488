import axios from 'axios';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { Header } from '../components/Detail/Header';
import Footer from '../components/Footer';
import MoreView from '../components/MoreView';
import Pagination from '../components/Pagination';
import { Skeleton } from '../components/Skeleton';
import { Thumbnail } from '../components/Thumbnail';
import apiServerPath from '../config/config';
import { BasketType, DetailBookType, KeywordType, RecommendType } from '../config/types';
import lib from '../lib/library';
import BasketStore from '../stores/basket';

const DetailView = () => {
  const [active, setActive] = useState(false);

  const basketStore = useMemo(() => {
    return BasketStore.getInstance();
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const { book_id } = useParams();

  const bookCondition = useMemo(() => {
    const status = query.get("book_condition");
    return status ? status : "0";
  }, [query]);

  const [bookInfo, setBookInfo] = useState<DetailBookType>({
    author_name: "",
    book_id: 0,
    book_name: "",
    book_sub_name: "",
    book_toc: "",
    image_url: "",
    into_the_book: null,
    intro_summary: "",
    price: "",
    publish_dt: "",
    publisher_comment: "",
    publisher_name: "",
    score_avg: 0,
    score_count: 0,
    selling_status: 0
  });

  const [recommends, setRecommends] = useState<RecommendType[]>([]);

  const [keyword, setKeyword] = useState<KeywordType[]>([]);

  const fetchData = useCallback(async () => {
    const promises = [
      /* 책 정보 */
      axios.get(`${apiServerPath}/bibly/api?cmd=book_detail&book_id=${book_id}&book_condition=${bookCondition}`),
      /* 연관 책 */
      axios.get(`${apiServerPath}/bibly/api?cmd=recommend_b2b&book_id=${book_id}`),
      /* 연관 키워드 */
      axios.get(`${apiServerPath}/bibly/api?cmd=recommend_b2k&book_id=${book_id}`)
    ];

    await axios.all(promises).then((res) => {
      const responseOne = res[0];
      if (responseOne.status === 200) setBookInfo(responseOne.data.list[0]);

      const responseTwo = res[1];
      if (responseTwo.status === 200) setRecommends(responseTwo.data.list);

      const responseThree = res[2];
      if (responseThree.status === 200) {
        setKeyword(responseThree.data.list.splice(0, 10));
      }
    });
  }, [book_id, bookCondition]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const bookName = useMemo(() => {
    return parseInt(bookCondition!) ? `[중고] ${bookInfo.book_name}` : bookInfo.book_name;
  }, [bookCondition, bookInfo.book_name]);

  const toggleFooter = useCallback(() => {
    setActive(!active);
  }, [active]);

  const realPrice = useMemo(() => {
    return lib.numberCommaFormat(parseInt(bookInfo.price)) + "원";
  }, [bookInfo]);

  const salePrice = useMemo(() => {
    return bookInfo.discount_rate
      ? lib.numberCommaFormat(parseInt(bookInfo.price) * (1 - bookInfo.discount_rate)) + "원"
      : lib.numberCommaFormat(parseInt(bookInfo.price) * 0.9) + "원";
  }, [bookInfo]);

  const mileage = useMemo(() => {
    return "(" + lib.numberCommaFormat(parseInt(bookInfo.price) * 0.05) + "점 적립)";
  }, [bookInfo]);

  const loading = useMemo(() => {
    return bookInfo.book_id ? false : true;
  }, [bookInfo.book_id]);

  const keywordList = useMemo(() => {
    return keyword.length ? (
      <Article>
        <Title>관련 키워드</Title>
        <KeyWordWrapper>
          {keyword.map((key: KeywordType, index) => (
            <Link to={`/search/${key.keyword}?btrListType=b2k&btrRank=${index}`}>
              <KeyWord key={key.keyword}>{`#${key.keyword}`}</KeyWord>
            </Link>
          ))}
        </KeyWordWrapper>
      </Article>
    ) : (
      <></>
    );
  }, [keyword]);

  const recommendList = useMemo(() => {
    return recommends.length ? (
      <Article>
        <Title>이 책과 함께 읽는 책들</Title>
        <RecommendWrapper>
          <Pagination>
            {recommends.map((data, index) => (
              <ThumbnailWrapper key={data.book_id}>
                <Link to={`/detail/${data.book_id}?btrListType=b2b&btrRank=${index}`}>
                  <RecommendThumbnail src={data.image_url} />
                  <RecommendTitle>{data.book_name}</RecommendTitle>
                </Link>
              </ThumbnailWrapper>
            ))}
          </Pagination>
        </RecommendWrapper>
      </Article>
    ) : (
      <></>
    );
  }, [recommends]);

  const handleBasket = useCallback(() => {
    const data: BasketType = {
      bookName: bookInfo.book_name,
      authorName: bookInfo.author_name,
      publisherName: bookInfo.publisher_name,
      bookId: bookInfo.book_id,
      imagePath: bookInfo.image_url,
      book_condition: 0
    };

    const trackQuery = {
      type: query.get("btrListType"),
      rank: query.get("btrRank")
    };

    basketStore.handleBasket(data, false, trackQuery);
  }, [basketStore, query, bookInfo]);

  const basketButton = useMemo(() => {
    return bookInfo.selling_status === 1 ? (
      <StyledButton variant="dark" value="책 담기" onClick={handleBasket} />
    ) : (
      <StyledButton disabled value="품절" />
    );
  }, [bookInfo.selling_status, handleBasket]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [bookInfo.book_id]);

  return (
    <Wrapper loading={loading}>
      <Header />
      <Content>
        <ImageSkeleton loading={loading}>
          <HeaderWrapper>
            <Thumbnail width={220} height={300} src={bookInfo.image_url} />
            {/* <Image src={bookInfo.image_url} alt="img" /> */}
            <InfoWrapper>
              <BookTitle>{bookName}</BookTitle>
              <Description>{bookInfo.book_sub_name}</Description>
              <div style={{ marginTop: 12 }}>
                <Span>{bookInfo.author_name}</Span>
                <Span>{bookInfo.publisher_name}</Span>
                <Span>{bookInfo.publish_dt}</Span>
              </div>
              <PriceWrapper>
                <RealPrice>{realPrice}</RealPrice>
                <SalePrice>{salePrice}</SalePrice>
                <Mileage>{mileage}</Mileage>
              </PriceWrapper>
              {basketButton}
            </InfoWrapper>
          </HeaderWrapper>
        </ImageSkeleton>
        <InnerSkeleton loading={loading}>{keywordList}</InnerSkeleton>
        <InnerSkeleton loading={loading}>{recommendList}</InnerSkeleton>
        <InnerSkeleton loading={loading}>
          <MoreView title="목차" content={bookInfo.book_toc}></MoreView>
        </InnerSkeleton>
        <InnerSkeleton loading={loading}>
          <MoreView style={{ marginTop: 8 }} title="도서 정보" content={bookInfo.intro_summary} />
        </InnerSkeleton>
      </Content>
      <Footer active={active} toggleFooter={toggleFooter} />
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ loading: boolean }>`
  background-color: ${(props) => !props.loading && "#e9ecef"};
  min-height: 100vh;
  padding-bottom: 3.5rem;
`;

const Content = styled("div")``;

const Article = styled("div")`
  margin-bottom: 8px;
  background-color: #fff;
  padding: 24px;
`;

const RecommendWrapper = styled("div")`
  margin-top: 24px;
`;

const ThumbnailWrapper = styled("div")`
  cursor: pointer;

  & + div {
    margin-left: 20px;
  }
`;

const RecommendThumbnail = styled(Thumbnail)`
  width: 125px;
  height: 200px;

  @media (max-width: 420px) {
    width: 88px;
    height: 132px;
  }
`;

const ImageSkeleton = styled(Skeleton)`
  height: 300px;
  margin: 24px;
`;

const HeaderWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 18px;
  background-color: #fff;
  margin-bottom: 8px;
`;

const InfoWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  font-size: 0.9rem;
`;

const Title = styled("div")`
  font-size: 22px;
  font-weight: 800;
`;

const BookTitle = styled("div")`
  font-weight: bold;
  font-size: 1.2rem;
  word-break: keep-all;
`;

const Span = styled("span")`
  & + span {
    border-left: 1px solid #ccc;
    margin-left: 8px;
    padding-left: 8px;
  }
`;

const Description = styled("span")`
  color: #ced4da;

  & + span {
    margin-top: 8px;
  }
`;

const InnerSkeleton = styled(Skeleton)`
  margin: 24px;
`;

const KeyWordWrapper = styled("div")`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
`;

const KeyWord = styled("div")`
  font-size: 0.9rem;
  background-color: #e9ecef;
  padding: 8px 16px;
  font-weight: bold;
  border-radius: 12px;
  white-space: nowrap;
  margin-right: 8px;
  margin-bottom: 8px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #ced4da;
  }
`;

const PriceWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`;

const RealPrice = styled("p")`
  color: #adb5bd;
  text-decoration: line-through;
  margin: 0;
`;

const SalePrice = styled("p")`
  color: #e8590c;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  margin-left: 8px;
`;

const Mileage = styled("p")`
  font-size: 12px;
  color: #adb5bd;
  margin: 0;
  margin-left: 4px;
`;

const StyledButton = styled(Button)`
  margin-top: 12px;
`;

const RecommendTitle = styled("div")`
  display: -webkit-box;
  font-size: 13px;
  overflow: hidden;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 12px;
`;

export default inject("basket")(observer(DetailView));
