import React, { useMemo } from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  subTitle: string;
  active: boolean;
  clickable?: boolean;
  focus?: boolean;
  date?: string;
  onClick?: () => void;
}

export const HeaderStatus = (props: Props) => {
  const { title, subTitle, active, date, focus, onClick, clickable } = props;

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (!clickable) return;
    onClick && onClick();
  };

  const dateTime = useMemo(() => {
    return date ? date : "";
  }, [date]);

  return (
    <Wrapper onClick={handleClick} active={active} clickable={clickable}>
      <Header>
        <Title focus={focus}>{title}</Title>
        <Date>{dateTime}</Date>
      </Header>
      <SubTitle>{subTitle}</SubTitle>
    </Wrapper>
  );
};

const Wrapper = styled("div")<{
  active: boolean | undefined;
  clickable: boolean | undefined;
}>`
  color: ${(props) => (props.active ? "#fff" : "#3e3e3e")};
  border-left: 1px solid ${(props) => (props.active ? "#fff" : "#3e3e3e")};

  padding-left: 25px;
  cursor: ${(props) => props.clickable && "pointer"};

  & + div {
    padding-top: 30px;
  }
`;

const Header = styled("div")`
  display: flex;
  align-items: center;
`;

const Title = styled("h1")<{ focus: boolean | undefined }>`
  margin: 0;
  font-weight: 800;
`;

const Date = styled("div")`
  margin-left: 30px;
`;

const SubTitle = styled("p")`
  margin: 0;
  font-size: 16px;
`;
