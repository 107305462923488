import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { BestBook } from '../components/BestBook';
import Divider from '../components/Divider';
import { FixedHeader } from '../components/FixedHeader';
import Footer from '../components/Footer';
import FooterInfo from '../components/FooterInfo';
import { Header } from '../components/Header';
import { SearchInput } from '../components/SearchInput';
import BasketStore from '../stores/basket';
import BestStore from '../stores/best';

interface Props {
  best: BestStore;
}

const Best = (props: Props) => {
  const { best } = props;

  const [active, setActive] = useState<boolean>(false);

  const basketStore = useMemo(() => {
    return BasketStore.getInstance();
  }, []);

  const bestBookList = useMemo(() => {
    return best.bestBooks.map((el, index) => {
      const isStored = basketStore.baskets.find((element) => el.book_id === element.bookId) !== undefined;

      return (
        <>
          <BestBook isStored={isStored} index={index} data={el} />
          <Divider />
        </>
      );
    });
  }, [best.bestBooks, basketStore.baskets]);

  const toggleFooter = useCallback(() => {
    setActive(!active);
  }, [active]);

  const onScroll = useCallback(() => {
    const { scrollHeight } = window.document.body;
    const { innerHeight, scrollY } = window;

    if (scrollHeight - innerHeight - scrollY <= 70 && best.fetchMore) {
      best.fetchMoreBestBooks();
    }
  }, [best]);

  useEffect(() => {
    best.fetchBestBooks();

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll, best]);

  return (
    <Container>
      <Header />
      <Content>
        <SearchInput />
        <FixedHeader />
        <SearchTitle>베스트 셀러</SearchTitle>
        {bestBookList}
        <Footer active={active} toggleFooter={toggleFooter} />
      </Content>
      <FooterInfo style={{ marginBottom: "3.5rem" }} />
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 100vh;
`;

const Content = styled("div")`
  padding: 0 24px;
`;

const SearchTitle = styled("p")`
  font-size: 18px;
  font-weight: 700;
`;

export default inject("best")(observer(Best));
