import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import downArrow from '../../../assets/icons/down_arrow.png';
import { HeaderStatus } from './HeaderStatus';

interface Props {
  processSeq: number;
  isBuyForm: boolean;
  processList: any;
  isCanceled: boolean;
  isReturned: boolean;
}

export const Header: React.FC<Props> = (props) => {
  const { processSeq, isBuyForm, processList, isCanceled, isReturned } = props;

  const [active, setActive] = useState(false);

  const toggleHeader = useCallback(() => {
    if (!isCanceled) {
      setActive(!active);
      window.scrollTo(0, 0);
    }
  }, [active, isCanceled]);

  const deliveryDate = useMemo(() => {
    return processList.length >= 7 ? moment(processList[6].process_date!).format("YYYY-MM-DD") : "";
  }, [processList]);

  const foldedHeader = useMemo(() => {
    if (isCanceled) {
      return <HeaderStatus title="주문 취소" subTitle="주문이 취소되었습니다." active />;
    } else if (isBuyForm) {
      return <HeaderStatus title="구매 결정" subTitle="" active />;
    } else {
      if (processSeq >= 1600) {
        return <HeaderStatus title={isReturned ? "리턴 완료" : "리턴 없음"} subTitle="" active />;
      } else if (processSeq >= 1300) {
        return <HeaderStatus title="구매 완료" subTitle={isReturned ? "리턴을 준비해주세요." : ""} active />;
      } else if (processSeq >= 1100) {
        return (
          <HeaderStatus
            title="배송 완료"
            subTitle="책이 도착 했나요? 5일간 즐겁게 경험하세요"
            active
            date={deliveryDate}
          />
        );
      } else if (processSeq >= 1000) {
        return <HeaderStatus title="배송 중" subTitle="책이 집으로 찾아가고 있어요" active />;
      } else {
        return <HeaderStatus title="주문 완료" subTitle="받아보기 신청한 책 목록 확인" active />;
      }
    }
  }, [processSeq, isBuyForm, deliveryDate, isCanceled, isReturned]);

  const headerContent = useMemo(() => {
    return active ? (
      <>
        <HeaderStatus title="주문 완료" subTitle="받아보기 신청한 책 목록 확인" active />
        <HeaderStatus title="배송 중" subTitle="책이 집으로 찾아가고 있어요" active={processSeq >= 1000} />
        <HeaderStatus
          title="배송 완료"
          subTitle="책이 도착 했나요? 5일간 즐겁게 경험하세요"
          active={processSeq >= 1100}
          date={deliveryDate}
        />
        <HeaderStatus
          title={processSeq >= 1300 ? "구매 완료" : "구매 결정"}
          subTitle={isReturned ? "리턴을 준비해주세요." : ""}
          active={processSeq >= 1100}
        />
        {/* <HeaderStatus title="리턴 준비 중" subTitle="" active={processSeq >= 1500} />  */}
        <HeaderStatus title="리턴 완료" subTitle="" active={processSeq >= 1600} />
      </>
    ) : (
      foldedHeader
    );
  }, [active, foldedHeader, deliveryDate, processSeq, isReturned]);

  return (
    <Wrapper active={active}>
      <ToggleButtonWrapper onClick={toggleHeader} active={active} show={!isCanceled}>
        <img alt="icon" src={downArrow} />
      </ToggleButtonWrapper>
      {headerContent}
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ active: boolean }>`
  height: 450px;
  position: relative;
  width: 100%;
  padding: 30px;
  background-color: #000;
  overflow-y: scroll;

  height: ${(props) => !props.active && "120px"};
  transition: height 0.4s;
`;

const ToggleButtonWrapper = styled("div")<{ active: boolean; show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  position: absolute;
  cursor: ${(props) => props.active && "pointer"};
  background-color: #000;
  width: 16px;
  height: 40px;
  margin-left: -6px;

  transform: ${(props) => (props.active ? "rotate(180deg)" : "rotate(0deg)")};
`;
