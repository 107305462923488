import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import lib from '../../lib/library';
import { Radio } from '../Radio';

interface Props {
  cardNo?: string | number;
  cardCompany?: string | number;
  cardType: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChecked: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

const DATA = ["card1", "card2", "card3", "card4"];

export const CardForm = (props: Props) => {
  const { cardNo, cardCompany, cardType, onChange, onChecked } = props;

  const handleNextFocus = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (lib.isInputElement(e.target.nextElementSibling)) {
        if (e.target.maxLength <= e.target.value.length) {
          e.target.nextElementSibling.focus();
        }
      }

      onChange(e);
    },
    [onChange]
  );

  const cardInputs = useMemo(() => {
    return DATA.map((name, i) => {
      const isPassword = i === 1 || i === 2;

      return (
        <React.Fragment key={name}>
          <Input
            type={isPassword ? "password" : "number"}
            name={name}
            maxLength={4}
            onChange={handleNextFocus}
            pattern="[0-9]*"
          />
        </React.Fragment>
      );
    });
  }, [handleNextFocus]);

  const registeredCard = useMemo(() => {
    return cardNo ? `${cardCompany} ${cardNo}` : "등록된 카드가 없습니다.";
  }, [cardCompany, cardNo]);

  const hasRegisteredCard = useMemo(() => {
    return !cardNo;
  }, [cardNo]);

  const newCardForm = useMemo(() => {
    return cardType !== "Registered" ? (
      <>
        <Row>
          <Title>카드번호</Title>
          <CardNumberForm>{cardInputs}</CardNumberForm>
        </Row>
        <Row style={{ flexDirection: "row" }}>
          <Col>
            <Title>카드 유효 기간</Title>
            <CardNumberForm>
              <Input name="ExpMonth" placeholder="MM" maxLength={2} onChange={handleNextFocus} pattern="[0-9]*" />
              <Input name="ExpYear" placeholder="YY" maxLength={2} onChange={handleNextFocus} pattern="[0-9]*" />
            </CardNumberForm>
          </Col>

          <Col style={{ marginLeft: 16 }}>
            <Title>비밀번호</Title>
            <CardNumberForm>
              <Input
                name="CardPw"
                type="password"
                onChange={handleNextFocus}
                maxLength={2}
                pattern="[0-9]*"
                inputMode="numeric"
              />
              <HideText>••</HideText>
            </CardNumberForm>
          </Col>
        </Row>

        <Row style={{ flexDirection: "row" }}>
          <Col>
            <Title>주민번호 앞6자리(또는 사업자번호 10자리)</Title>
            <CardNumberForm>
              <Input name="IDNo" type="number" onChange={handleNextFocus} maxLength={10} />
            </CardNumberForm>
          </Col>
        </Row>
        <Warning>카드 비밀번호 입력 3회 이상 오류 시, 신용카드 거래가 제한됩니다.</Warning>
        <Warning style={{ marginTop: 3 }}>
          비밀번호 오류 해제를 위해 각 발급사의 고객센터로 문의하시거나, 가까운 영업점을 방문하시어 비밀번호를 변경해
          주시기 바랍니다.
        </Warning>
      </>
    ) : null;
  }, [cardType, cardInputs, handleNextFocus]);

  const cardSelectForm = useMemo(() => {
    return cardNo ? (
      <>
        <StyledRadio
          text="등록된 카드"
          name="cardType"
          value="Registered"
          disabled={hasRegisteredCard}
          handleChecked={onChecked}
          defaultChecked
        />
        <CardNumber>{registeredCard}</CardNumber>

        <StyledRadio text="신규 카드" name="cardType" value="New" handleChecked={onChecked} />
        {newCardForm}
      </>
    ) : (
      <>
        <Row>
          <Title>카드번호</Title>
          <CardNumberForm>{cardInputs}</CardNumberForm>
        </Row>
        <Row style={{ flexDirection: "row" }}>
          <Col>
            <Title>카드 유효 기간</Title>
            <CardNumberForm>
              <Input name="ExpMonth" placeholder="MM" maxLength={2} onChange={handleNextFocus} pattern="[0-9]*" />
              <Input name="ExpYear" placeholder="YY" maxLength={2} onChange={handleNextFocus} pattern="[0-9]*" />
            </CardNumberForm>
          </Col>

          <Col style={{ marginLeft: 16 }}>
            <Title>비밀번호</Title>
            <CardNumberForm>
              <Input
                name="CardPw"
                type="password"
                onChange={handleNextFocus}
                maxLength={2}
                pattern="[0-9]*"
                inputMode="numeric"
              />
              <HideText>••</HideText>
            </CardNumberForm>
          </Col>
        </Row>

        <Row style={{ flexDirection: "row" }}>
          <Col>
            <Title>주민번호 앞6자리(또는 사업자번호 10자리)</Title>
            <CardNumberForm>
              <Input name="IDNo" type="number" onChange={handleNextFocus} maxLength={10} />
            </CardNumberForm>
          </Col>
        </Row>
        <Warning>카드 비밀번호 입력 3회 이상 오류 시, 신용카드 거래가 제한됩니다.</Warning>
        <Warning style={{ marginTop: 3 }}>
          비밀번호 오류 해제를 위해 각 발급사의 고객센터로 문의하시거나, 가까운 영업점을 방문하시어 비밀번호를 변경해
          주시기 바랍니다.
        </Warning>
      </>
    );
  }, [cardNo, hasRegisteredCard, newCardForm, onChecked, registeredCard, cardInputs, handleNextFocus]);

  return (
    <Wrapper>
      <Header>카드 정보</Header>
      <Description>
        5일간 먼저 읽어보시고, 구매할 책들만{" "}
        <Description style={{ color: "#000", fontWeight: "bold" }}>간편결제</Description> 됩니다
        {cardSelectForm}
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  margin-top: 12px;
`;

const Header = styled("h2")`
  margin: 50px 0px 0px 0px;
`;

const Title = styled("h2")`
  color: #666;
  font-weight: normal;
  font-size: 0.875rem;
`;

const Row = styled("div")`
  display: flex;
  flex-direction: column;
`;

const Col = styled("div")`
  flex: 1;
`;

const CardNumberForm = styled("div")`
  display: flex;
`;

const HideText = styled("p")`
  flex: 1;
  height: 35px;
  margin: 0;
  line-height: 35px;
  font-size: 14px;
`;

const Warning = styled("li")`
  color: #adb5bd;
  margin-top: 12px;
  font-size: 13px;
  word-break: keep-all;
`;

const Description = styled("span")`
  font-size: 13px;
  color: #adb5bd;
  padding-bottom: 12px;
  word-break: keep-all;
`;

const Input = styled("input")`
  font-size: 14px;
  outline: none;
  border-radius: 3px;
  width: 100%;
  -webkit-appearance: none;

  border: 1px solid #e9ecef;

  ::placeholder {
    color: #adb5bd;
    opacity: 1;
  }

  &:focus {
    border: 1px solid #000;
  }

  flex: 1;
  padding: 8px;
  font-size: 14px;

  & + input {
    margin-left: 10px;
  }

  & + p {
    margin-left: 10px;
  }
`;

const StyledRadio = styled(Radio)`
  margin-top: 24px;
`;

const CardNumber = styled("p")``;
