import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Loading from '../assets/icons/loading.gif';
import Divider from '../components/Divider';
import EventPopup from '../components/EventPopup';
import { FixedHeader } from '../components/FixedHeader';
import Footer from '../components/Footer';
import FooterInfo from '../components/FooterInfo';
import { Header } from '../components/Header';
import RecommendBook from '../components/RecommendBook';
import SearchInput from '../components/SearchInput';
import BasketStore from '../stores/basket';
import SearchStore from '../stores/search';

interface Props extends InjectedProps {}

interface InjectedProps {
  search: SearchStore;
}

const Main = (props: Props) => {
  const { search } = props;
  const [active, setActive] = useState<boolean>(false);

  const basketStore = useMemo(() => {
    return BasketStore.getInstance();
  }, []);

  const { book_name } = useParams();

  const onScroll = useCallback(() => {
    const { scrollHeight } = window.document.body;
    const { innerHeight, scrollY } = window;

    if (scrollHeight - innerHeight - scrollY <= 70 && search.fetchPopularMore) {
      search.fetchMorePopulars();
    }
  }, [search]);

  useEffect(() => {
    if (!search.popularBookList.length) {
      search.fetchPopulars();
    }

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [book_name, onScroll, search]);

  const spinner = useMemo(() => {
    return search.isFetching && <FetchIcon src={Loading} />;
  }, [search.isFetching]);

  const contents = useMemo(() => {
    return search.popularBooks.map((el, index) => {
      const isStored = basketStore.baskets.find((element) => el.book_id === element.bookId) !== undefined;
      return (
        <React.Fragment key={el.book_id}>
          <RecommendBook isStored={isStored} data={el} index={index} />
          <Divider />
        </React.Fragment>
      );
    });
  }, [basketStore.baskets, search.popularBooks]);

  const toggleFooter = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <Wrapper>
      <EventPopup />
      <Header />
      <Content>
        <StyledSearchInput />
        <FixedHeader removeMargin />

        <Article>
          <SearchTitle>요즘 가장 많이 찾아가는 책들!</SearchTitle>
          {contents}
          {spinner}
        </Article>
      </Content>

      <FooterInfo style={{ marginBottom: "3.5rem" }} />
      <Footer active={active} toggleFooter={toggleFooter} />
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;

const Content = styled("div")``;

const Article = styled("div")`
  padding: 0 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const FetchIcon = styled("img")`
  margin: 0 auto;
  width: 70px;
  height: 70px;
`;

const SearchTitle = styled("p")`
  font-size: 18px;
  font-weight: 700;
  word-break: keep-all;
`;

const StyledSearchInput = styled(SearchInput)`
  margin: 24px 24px 0px;
  width: auto;
`;

export default inject("search")(observer(Main));
