import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  content: string;
  categoryId: string;
}

export const CategoryItem = (props: Props) => {
  const { content, categoryId } = props;

  return <Wrapper to={`/categorySearch?category=${content}&category_id=${categoryId}`}>{content}</Wrapper>;
};

const Wrapper = styled(Link)`
  display: flex;
  background-color: #f1f3f5;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  color: #000;
  text-decoration: none;

  &:hover {
    background-color: #dee2e6;
  }
`;
