import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Loading from '../assets/icons/loading.gif';
import Book from '../components/Book';
import Divider from '../components/Divider';
import EventPopup from '../components/EventPopup';
import Footer from '../components/Footer';
import FooterInfo from '../components/FooterInfo';
import { Header } from '../components/Header';
import SearchInput from '../components/SearchInput';
import { UsedBook } from '../components/UsedBook';
import BasketStore from '../stores/basket';
import SearchStore from '../stores/search';

interface Props extends InjectedProps {}

interface InjectedProps {
  search: SearchStore;
}

const Search = (props: Props) => {
  const { search } = props;
  const [active, setActive] = useState<boolean>(false);
  const [isUsed, setIsUsed] = useState<boolean>(false);

  const basketStore = useMemo(() => {
    return BasketStore.getInstance();
  }, []);

  const { book_name } = useParams();

  const onScroll = useCallback(() => {
    const { scrollHeight } = window.document.body;
    const { innerHeight, scrollY } = window;

    if (book_name) {
      if (
        book_name &&
        scrollHeight - innerHeight - scrollY <= 70 &&
        search.fetchMore &&
        search.more &&
        search.isFetching === false &&
        isUsed === false
      ) {
        const data = {
          bookName: search.bookName,
          size: 30,
          next: search.more
        };

        search.searchMoreBooks(data);
      }
    } else {
      if (scrollHeight - innerHeight - scrollY <= 70 && search.fetchPopularMore) {
        search.fetchMorePopulars();
      }
    }
  }, [search, book_name, isUsed]);

  const searchBooks = useCallback(
    (bookName: string) => {
      search.setBookName(decodeURIComponent(bookName));

      const data = {
        bookName: search.bookName,
        size: 30
      };
      search.searchBooks(data);
    },
    [search]
  );

  const setSearch = useCallback(() => {
    setIsUsed(false);
  }, [setIsUsed]);

  const searchTitleColor = useMemo(() => {
    return isUsed ? "#ced4da" : "#000";
  }, [isUsed]);

  const usedTitleColor = useMemo(() => {
    return isUsed ? "#000" : "#ced4da";
  }, [isUsed]);

  const setUsed = useCallback(() => {
    setIsUsed(true);
  }, [setIsUsed]);

  useEffect(() => {
    if (book_name) {
      searchBooks(book_name);
      search.searchUsedBooks(book_name);
    }

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [book_name, onScroll, search, searchBooks]);

  const spinner = useMemo(() => {
    return search.isFetching && <FetchIcon src={Loading} />;
  }, [search.isFetching]);

  const searchTitle = useMemo(() => {
    return `'${search.bookName}' 검색결과 [${search.search.count}]건`;
  }, [search.bookName, search.search.count]);

  const searchList = useMemo(() => {
    return (
      <>
        <SearchHeader>
          <ToggleTitle onClick={setSearch} color={searchTitleColor}>
            {searchTitle}
          </ToggleTitle>
          <ToggleTitle onClick={setUsed} color={usedTitleColor}>
            중고책
          </ToggleTitle>
        </SearchHeader>

        {isUsed
          ? search.usedSearch.data.map((el, index) => {
              const isStored = basketStore.baskets.find((element) => el.book_id === element.bookId) !== undefined;

              return (
                <div key={index}>
                  <UsedBook isStored={isStored} data={el} />
                  <Divider />
                </div>
              );
            })
          : search.search.data.map((el, index) => {
              const isStored =
                basketStore.baskets.find((element) => el._id === element.bookId.toString()) !== undefined;

              return (
                <div key={el._id}>
                  <Book keyword={search.bookName} index={index} isStored={isStored} data={el} />
                  <Divider />
                </div>
              );
            })}
      </>
    );
  }, [
    searchTitle,
    searchTitleColor,
    setSearch,
    basketStore.baskets,
    isUsed,
    search.search.data,
    search.usedSearch.data,
    setUsed,
    usedTitleColor,
    search.bookName
  ]);

  const toggleFooter = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <Wrapper>
      <EventPopup />
      <Header />
      <Content>
        <SearchInput />
        <Article>
          {searchList}
          {spinner}
        </Article>
      </Content>
      <FooterInfo style={{ marginBottom: "3.5rem" }} />
      <Footer active={active} toggleFooter={toggleFooter} />
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;

const Content = styled("div")`
  padding: 0 24px;
`;

const Article = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const FetchIcon = styled("img")`
  margin: 0 auto;
  width: 70px;
  height: 70px;
`;

const SearchTitle = styled("p")`
  font-size: 18px;
  font-weight: 700;
  word-break: keep-all;
`;

const ToggleTitle = styled(SearchTitle)<{ color: string }>`
  cursor: pointer;
  color: ${(props) => props.color};
`;

const SearchHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default inject("search")(observer(Search));
