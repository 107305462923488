import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/Button';
import FooterInfo from '../components/FooterInfo';
import { Header } from '../components/Header';
import { Input } from '../components/Input';
import Modal from '../components/Modal';
import useInput from '../hooks/useInput';
import useWarning from '../hooks/useWarning';
import lib from '../lib/library';
import UserStore from '../stores/user';

const FindEmail: React.FC = () => {
  const history = useHistory();

  const [state, onChange] = useInput({
    user_phone: "",
    certification_number: ""
  });

  const [warning, setWarning] = useWarning({
    phone: "",
    certification_number: ""
  });

  const [token, setToken] = useState("");
  const [opened, setOpened] = useState(false);
  const [email, setEmail] = useState("");

  const { user_phone, certification_number } = state;

  const userStore = useMemo(() => {
    return UserStore.getInstance();
  }, []);

  const validatePhone = useCallback(() => {
    if (!user_phone) {
      setWarning({ phone: "전화번호를 입력해주세요." });
      return false;
    } else if (!lib.phoneValidate(user_phone)) {
      setWarning({ phone: "전화번호 양식을 확인해주세요." });
      return false;
    } else {
      setWarning({ phone: "" });
      return true;
    }
  }, [user_phone, setWarning]);

  const validateCertNum = useCallback(() => {
    if (!certification_number) {
      setWarning({ certification_number: "인증번호를 입력해주세요." });
      return false;
    } else {
      setWarning({ certification_number: "" });
      return true;
    }
  }, [certification_number, setWarning]);

  const sendCertNum = useCallback(async () => {
    const phoneValidate = validatePhone();

    if (phoneValidate) {
      const response = await userStore.authPhoneForEmail(user_phone);
      if (response.status === 200) {
        setToken(response.data.token_string);
      }
    }
  }, [user_phone, userStore, setToken, validatePhone]);

  const chkCertNum = useCallback(async () => {
    const certNumValidate = validateCertNum();

    if (certNumValidate) {
      const response = await userStore.checkNumberForEmail(user_phone, certification_number, token);
      if (response.status === 200) {
        setEmail(response.data.email);
        setOpened(true);
      }
    }
  }, [userStore, user_phone, certification_number, token, setEmail, validateCertNum]);

  const authForm = useMemo(() => {
    return (
      token && (
        <AuthForm>
          <Input
            name="certification_number"
            label="인증번호"
            placeholder="인증번호 6자리를 입력해주세요."
            onChange={onChange}
            onBlur={validateCertNum}
            warning={warning.certification_number}
          />
          <StyledButton value="인증하기" variant="dark" onClick={chkCertNum} />
        </AuthForm>
      )
    );
  }, [token, onChange, chkCertNum, validateCertNum, warning.certification_number]);

  const redirectLogin = useCallback(() => {
    history.push("/login");
  }, [history]);

  const closeModal = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  return (
    <Container>
      <Header />
      <Content>
        <Title>아이디 찾기</Title>
        <Description>계정에 연결된 전화번호 인증을 하시면 아이디를 알려 드릴게요.</Description>
        <Input
          name="user_phone"
          label="전화번호"
          placeholder="-를 제외한 휴대전화 번호를 입력해주세요."
          onChange={onChange}
          onBlur={validatePhone}
          warning={warning.phone}
        />
        <StyledButton value="인증번호 발송하기" variant="dark" onClick={sendCertNum} />
        {authForm}
      </Content>
      <FooterInfo />
      <Modal opened={opened} handleClose={closeModal}>
        <EmailWrapper>
          <EmailDescriptionText>
            회원님의 계정 아이디(이메일)는 <EmailText>{email}</EmailText>입니다.
          </EmailDescriptionText>
          <StyledButton value="로그인하러가기" variant="dark" onClick={redirectLogin} />
        </EmailWrapper>
      </Modal>
    </Container>
  );
};

const Container = styled("div")``;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  max-width: 408px;
  margin: 0 auto;
  min-height: calc(100vh - 58px);
  padding: 64px 0px 128px;

  @media (max-width: 408px) {
    padding: 64px 24px 128px;
  }
`;

const Title = styled("h1")`
  font-size: 32px;
`;

const Description = styled("div")`
  font-size: 14px;
  margin-bottom: 24px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`;

const AuthForm = styled("div")`
  margin-top: 16px;
`;

const EmailWrapper = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const EmailDescriptionText = styled("p")`
  color: #495057;
  font-size: 14px;
`;

const EmailText = styled("span")`
  color: #212529;
  font-size: 13px;
  font-weight: bold;
`;

export default FindEmail;
