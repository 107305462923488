import React from 'react';
import styled from 'styled-components';

import { FindPasswordType } from '../components/FindPasswordType';
import FooterInfo from '../components/FooterInfo';
import { Header } from '../components/Header';

const FindPassword = () => {
  return (
    <Container>
      <Header />
      <Content>
        <Title>비밀번호 찾기</Title>
        <Description>인증방법을 선택해주세요.</Description>
        <FindPasswordType title="연락처로 찾기" description="등록된 연락처로 인증" routes="/find-password/phone" />
        <FindPasswordType
          style={{ marginTop: 24 }}
          title="이메일로 찾기"
          description="등록된 이메일로 인증"
          routes="/find-password/email"
        />
      </Content>
      <FooterInfo />
    </Container>
  );
};

const Container = styled("div")``;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  max-width: 408px;
  margin: 0 auto;
  min-height: calc(100vh - 58px);
  padding: 64px 0px 128px;

  @media (max-width: 408px) {
    padding: 64px 24px 128px;
  }
`;

const Title = styled("h1")`
  font-size: 32px;
`;

const Description = styled("div")`
  font-size: 14px;
  margin-bottom: 24px;
`;

export default FindPassword;
