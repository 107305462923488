import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import ErrorImage from '../../assets/img_empty_book.png';

interface Props {
  width: number;
  height: number;
  src: string;
  onClick?: () => void;
}

export const Thumbnail = (props: Props) => {
  const { width, height, src, onClick, ...restProps } = props;
  const [error, setError] = useState<boolean>(false);

  const thumbmail = useMemo(() => {
    if (src) {
      return error ? ErrorImage : src;
    } else {
      return ErrorImage;
    }
  }, [error, src]);

  const onError = useCallback(() => {
    setError(true);
  }, []);

  return (
    <BookItem
      width={width}
      height={height}
      alt="thumbmail"
      src={thumbmail}
      onClick={onClick}
      onError={onError}
      {...restProps}
    />
  );
};

Thumbnail.defaultProps = {
  width: 88,
  height: 132
};

const BookItem = styled("img")<{ width: number; height: number }>`
  width: ${(props) => props.width + "px"};
  height: ${(props) => props.height + "px"};
  /* width: 88px;
  height: 132px; */
  cursor: pointer;
`;
