import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { Banner } from "../components/Banner";
import Divider from "../components/Divider";
import { FixedHeader } from "../components/FixedHeader";
import Footer from "../components/Footer";
import FooterInfo from "../components/FooterInfo";
import { Header } from "../components/Header";
import { SearchInput } from "../components/SearchInput";
import { UsedBook } from "../components/UsedBook";
import BasketStore from "../stores/basket";
import UsedStore from "../stores/used";

interface Props {
  used: UsedStore;
}

const Used = (props: Props) => {
  const { used } = props;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const [active, setActive] = useState<boolean>(false);

  const basketStore = useMemo(() => {
    return BasketStore.getInstance();
  }, []);

  const onScroll = useCallback(() => {
    const { scrollHeight } = window.document.body;
    const { innerHeight, scrollY } = window;

    if (scrollHeight - innerHeight - scrollY <= 70 && used.fetchMore) {
      used.fetchMoreUsedBooks();
    }
  }, [used]);

  const usedList = useMemo(() => {
    return used.usedBooks.map((el, index) => {
      const isStored =
        basketStore.baskets.find((element) => el.book_id === element.bookId) !==
        undefined;

      return (
        <div key={index}>
          <UsedBook isStored={isStored} data={el} index={index} />
          <Divider />
        </div>
      );
    });
  }, [used.usedBooks, basketStore.baskets]);

  const toggleFooter = useCallback(() => {
    setActive(!active);
  }, [active]);

  useEffect(() => {
    used.fetchUsedBooks();

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [used, onScroll]);

  useEffect(() => {
    const autoPutting = async () => {
      if (query.get("bookId")) {
        sessionStorage.removeItem("books");
        await basketStore.autoPutting(query.get("bookId")!);
        sessionStorage.setItem("userId", query.get("userId")!);
        // window.history.replaceState({}, "", window.location.pathname);
        setActive(true);
      }
    };
    autoPutting();
  }, [basketStore]);

  function link() {
    let check = false;
    let url = "";
    const a = window.navigator.userAgent;

    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true;
    }

    if (check) {
      url = "https://bibly.page.link/2irR";
    } else {
      url =
        "https://play.google.com/store/apps/details?id=com.ryencatchers.bibly";
    }
    return url;
  }

  useEffect(() => {
    link();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        backgroundColor: "#404040",
      }}
    >
      <a href={link()} style={{ maxWidth: "100%" }}>
        <img
          src="https://bibly-etc-server-korea.s3.ap-northeast-2.amazonaws.com/etc/banner/gobibly_service_closed2.png"
          style={{ maxWidth: "100%" }}
        />
      </a>
    </div>
    // <Container>
    //   <Header />
    //   <Content>
    //     <StyledSearchInput />
    //     <FixedHeader removeMargin />
    //     <Banner />
    //     <Article>
    //       <SearchTitle>딱 한 번 펼쳐본 책들 25%</SearchTitle>
    //       {usedList}
    //       <Footer active={active} toggleFooter={toggleFooter} />
    //     </Article>
    //   </Content>
    //   <FooterInfo style={{ marginBottom: "3.5rem" }} />
    // </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 100vh;
`;

const Content = styled("div")``;

const Article = styled("div")`
  padding: 0 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const SearchTitle = styled("p")`
  font-size: 18px;
  font-weight: 700;
`;

const StyledSearchInput = styled(SearchInput)`
  margin: 24px 24px 0px;
  width: auto;
`;

export default inject("used")(observer(Used));
