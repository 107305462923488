import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

interface Props {
  style?: React.CSSProperties;
  name?: string;
  placeholder?: string;
  type?: string;
  hideBorder?: boolean;
  maxLength?: number;
  value?: string;
  pattern?: string;
  inputMode?: "text" | "none" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined;
  nextFocus?: boolean;
  label?: string;
  warning?: string;
  disabled?: boolean;
  inputStyle?: React.CSSProperties;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onSubmit?: (value: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input: React.FC<Props> = (props) => {
  const {
    style,
    name,
    placeholder,
    type,
    hideBorder,
    maxLength,
    value,
    pattern,
    inputMode,
    label,
    warning,
    disabled,
    inputStyle,
    onBlur,
    onChange,
    onSubmit,
    ...restProps
  } = props;

  const [text, setText] = useState<string>("");

  const inputValue = useMemo(() => {
    return value ? value : text;
  }, [value, text]);

  const handleSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 13) {
        onSubmit && onSubmit(inputValue);
      }
    },
    [onSubmit, inputValue]
  );

  const isValid = useMemo(() => {
    if (maxLength) {
      return inputValue.length === maxLength;
    }
  }, [maxLength, inputValue]);

  const textLabel = useMemo(() => {
    return label && <Label>{label}</Label>;
  }, [label]);

  const warningLabel = useMemo(() => {
    return warning && <Warning>{warning}</Warning>;
  }, [warning]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);

      onChange && onChange(e);
    },
    [onChange]
  );

  return (
    <Wrapper style={style} {...restProps}>
      {textLabel}
      <StyledInput
        style={inputStyle}
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        onKeyUp={handleSubmit}
        onChange={handleChange}
        type={type}
        hideBorder={hideBorder}
        maxLength={maxLength}
        value={inputValue}
        pattern={pattern}
        inputMode={inputMode}
        isValid={isValid!}
        warning={warning}
        disabled={disabled}
      />
      {warningLabel}
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledInput = styled("input")<{ hideBorder: boolean | undefined; isValid: boolean; warning: string | undefined }>`
  font-size: 14px;
  outline: none;
  padding: 12px;
  border-radius: 3px;
  width: 100%;
  -webkit-appearance: none;

  border: ${(props) => {
    if (props.hideBorder) return "none";
    else if (props.warning) return "1px solid #f03e3e";
    else return "1px solid #e9ecef";
  }};

  ${(props) => props.isValid && "border: 1px solid #000"};

  ::placeholder {
    color: #adb5bd;
    opacity: 1;
  }

  &:focus {
    ${(props) => !props.hideBorder && !props.warning && "border: 1px solid #000;"}
  }
`;

const Label = styled("label")`
  font-size: 14px;
  line-height: 20px;
  color: rgb(62, 64, 66);
  margin: 0px 0px 4px;
`;

const Warning = styled("span")`
  color: #f03e3e;
  font-size: 12px;
  margin-top: 4px;
`;
