import React from 'react';
import styled from 'styled-components';

interface Props {
  style?: React.CSSProperties;
}

const FooterInfo = (props: Props) => (
  <Wrapper style={props.style}>
    <Text>㈜ 라이앤캐처스</Text>
    <Text>서울특별시 서초구 강남대로 527, 패스트파이브 13충</Text>
    <Text>대표이사 : 허윤</Text>
    <Text>사업자등록번호 : 744-88-00108</Text>
    <Text>대표전화 : 02-6954-0730 팩스 : 02-516-9412</Text>
    <Text>서울특별시 통신판매업신고번호 : 서울마포-0653</Text>
    <Text>
      ▶
      <a rel="noopener noreferrer" href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7448800108" target="_blank">
        사업자정보확인
      </a>
    </Text>
  </Wrapper>
);

const Wrapper = styled("footer")`
  font-size: 12px;
  color: #868e96;
  text-align: center;
  padding: 30px;
  margin-top: auto;
  border-top: 1px solid #f8f9fa;
`;

const Text = styled("p")`
  margin: 0;
`;

export default FooterInfo;
