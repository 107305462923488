import React, { useState } from 'react';
import styled from 'styled-components';

import DelButton from '../assets/icons/btn_book_del.png';
import NUM1 from '../assets/icons/ico_num_1.png';
import NUM2 from '../assets/icons/ico_num_2.png';
import NUM3 from '../assets/icons/ico_num_3.png';
import NUM4 from '../assets/icons/ico_num_4.png';
import NUM5 from '../assets/icons/ico_num_5.png';
import ICON_PLUS from '../assets/icons/ico_plus.png';
import { Thumbnail } from '../components/Thumbnail';
import { BasketType } from '../config/types';

interface Props {
  id: number;
  data: BasketType;
  onDelete: (id: number, book_condition: number) => void;
}

const BookItem: React.FC<Props> = ({ id, data, onDelete }) => {
  const [hovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const handleDelete = () => {
    onDelete(data.bookId, data.book_condition);
  };

  const returnImage = () => {
    switch (id) {
      case 1:
        return NUM1;
      case 2:
        return NUM2;
      case 3:
        return NUM3;
      case 4:
        return NUM4;
      case 5:
        return NUM5;
      default:
        return ICON_PLUS;
    }
  };

  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {data ? (
        <div>
          <DeleteButton src={DelButton} onClick={handleDelete} />
          <Thumbnail src={data.imagePath} />
          <TitleToolTip hovered={hovered}>{data.bookName}</TitleToolTip>
        </div>
      ) : (
        <img src={returnImage()} alt="default" />
      )}
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid #f2f3f5;
  margin-top: 1rem;
  width: calc(33.3% - 1rem);
  height: 132px;
`;

const TitleToolTip = styled("div")<{ hovered: boolean }>`
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  bottom: 0;
  box-sizing: border-box;
  font-size: 0.85rem;
  font-weight: bold;
  transition: 0.3s;
  opacity: ${(props) => (props.hovered ? 1 : 0)};
  right: 0;
  padding: 5px;
  bottom: 0;
`;

const DeleteButton = styled("img")`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
`;

export default BookItem;
