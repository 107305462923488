import React from 'react';
import styled from 'styled-components';

import RightArrow from './Icon/RightArrow';

interface Props {
  text?: string;
  showIcon: boolean;
  style?: React.CSSProperties;
  isBold: boolean;
  fontSize?: number;
  color: string;
  removePadding?: boolean;
  onClick?: () => void;
}

const TextButton = (props: Props) => {
  const { text, onClick, showIcon, isBold, fontSize, color, ...restProps } = props;
  return (
    <Wrapper onClick={onClick} {...restProps}>
      <Text isBold={isBold} fontSize={fontSize} color={color}>
        {text}
      </Text>
      <Icon show={showIcon} size={fontSize} color={color} />
    </Wrapper>
  );
};

TextButton.defaultProps = {
  showIcon: true,
  isBold: true,
  color: "#000"
};

const Wrapper = styled("div")`
  display: flex;
  width: 100%;
  cursor: pointer;
  padding: 12px;

  align-items: center;
  justify-content: center;

  @media (max-height: 700px) {
    position: relative;
  }
`;

const Text = styled("p")<{
  isBold: boolean;
  fontSize: number | undefined;
  color: string | undefined;
}>`
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "1.2rem")};
  font-weight: ${(props) => props.isBold && "900"};
  margin: 0;
`;

const Icon = styled(RightArrow)<{ show: boolean; size: number | undefined }>`
  display: ${(props) => !props.show && "none"};
  margin-left: 1rem;
  width: ${(props) => props.size && `${props.size}px`};
`;

export default TextButton;
