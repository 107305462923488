import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import CloseIcon from '../assets/icons/btn_cross.png';

interface Props {
  opener?: React.ReactElement<{ onClick: () => void }>;
  opened?: boolean;
  children?: any;
  title?: string;
  removeHeader?: boolean;
  removeContentPadding: boolean;
  removeBorder: boolean;
  align: string;
  handleClose?: () => void;
  footer?: React.ReactElement;
}

const Modal = (props: Props) => {
  const {
    children,
    opener,
    opened,
    title,
    removeHeader,
    removeContentPadding,
    removeBorder,
    align,
    handleClose,
    footer
  } = props;

  const [show, setShow] = useState<boolean>(false);

  const isOpened = useMemo(() => {
    if (opened !== undefined) {
      return opened;
    } else {
      return show;
    }
  }, [opened, show]);

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpened]);

  const handleChildClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  const showModal = useCallback(() => {
    setShow(true);
  }, []);

  const closeModal = useCallback(() => {
    setShow(false);
  }, []);

  const clonedOpener = useMemo(() => {
    return (
      opener &&
      React.cloneElement(opener, {
        onClick: showModal
      })
    );
  }, [opener, showModal]);

  const header = useMemo(() => {
    return (
      !removeHeader && (
        <Header>
          <Title>{title}</Title>
          <Icon onClick={handleClose ? handleClose : closeModal} src={CloseIcon} alt="icon" />
        </Header>
      )
    );
  }, [removeHeader, title, closeModal, handleClose]);

  const footerContent = useMemo(() => {
    return footer && <Footer>{footer}</Footer>;
  }, [footer]);

  return (
    <>
      {clonedOpener}
      <Wrapper onClick={handleClose ? handleClose : closeModal} opened={isOpened} align={align}>
        <ModalInner removeBorder={removeBorder} onClick={handleChildClick}>
          {header}
          <Content removeContentPadding={removeContentPadding}>{children}</Content>
          {footerContent}
        </ModalInner>
      </Wrapper>
    </>
  );
};

Modal.defaultProps = {
  removeContentPadding: false,
  align: "center",
  removeBorder: false
};

const Wrapper = styled("div")<{ opened: boolean; align: string }>`
  display: flex;
  align-items: ${(props) => {
    if (props.align === "top") return "flex-start";
    if (props.align === "center") return "center";
    else return "center";
  }};
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  opacity: ${(props) => (props.opened ? "1" : "0")};
  visibility: ${(props) => (props.opened ? "visible" : "hidden")};
  transition: 0.25s;
`;

const Header = styled("div")`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const Title = styled("h3")`
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin: 0px;
  flex: 1;
  word-break: keep-all;
`;

const ModalInner = styled("div")<{ removeBorder: boolean }>`
  display: flex;
  flex-direction: column;
  width: 480px;
  min-height: 320px;
  background-color: #fff;
  border-radius: ${(props) => !props.removeBorder && "8px"};
  padding: 32px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

  @media (max-width: 500px) {
    width: 90%;
  }

  @media (max-height: 800px) {
    min-height: 50%;
    max-height: 90%;
    border-radius: 0;
  }
`;

const Content = styled("div")<{ removeContentPadding: boolean }>`
  margin: ${(props) => props.removeContentPadding && "-32px"};
  display: flex;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Icon = styled("img")`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const Footer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export default Modal;
