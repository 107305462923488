import { inject, observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { BorderBar } from '../components/BorderBar';
import { Button } from '../components/Button';
import FooterInfo from '../components/FooterInfo';
import { Header } from '../components/Header';
import { Input } from '../components/Input';
import useInput from '../hooks/useInput';
import UserStore from '../stores/user';

const Login: React.FC = () => {
  const [state, onChange] = useInput({
    email: "",
    user_password: ""
  });

  const history = useHistory();

  const { email, user_password } = state;

  const userStore = useMemo(() => {
    return UserStore.getInstance();
  }, []);

  const handleLogin = useCallback(async () => {
    const response = await userStore.login(email, user_password);

    if (response.status === 200) {
      history.push(`/?btrCid=${userStore.userInfo?.customer_id}`);
    }
  }, [email, user_password, userStore, history]);

  const onEnterPress = useCallback(() => {
    handleLogin();
  }, [handleLogin]);

  return (
    <Container>
      <Header />
      <Content>
        <InputWrapper>
          <Input style={{ marginBottom: 16 }} label="이메일" name="email" onChange={onChange} onSubmit={onEnterPress} />
          <Input type="password" label="비밀번호" name="user_password" onChange={onChange} onSubmit={onEnterPress} />
        </InputWrapper>

        <SignUpWrapper>
          <UnderLineText to="/find-email">아이디 찾기</UnderLineText>
          <BorderBar />
          <UnderLineText to="/find-password">비밀번호 찾기</UnderLineText>
          <BorderBar />
          <RegisterText to="/register">회원가입 하기</RegisterText>
        </SignUpWrapper>
        <LoginBtn value="로그인" variant="dark" onClick={handleLogin} />
      </Content>
      <FooterInfo />
    </Container>
  );
};

const Container = styled("div")``;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 408px;
  margin: 0 auto;
  min-height: calc(100vh - 58px);
  padding: 64px 0px 128px;

  @media (max-width: 408px) {
    padding: 64px 24px 128px;
  }
`;

const InputWrapper = styled("div")`
  width: 100%;
`;

const LoginBtn = styled(Button)`
  margin-top: 18px;
  width: 100%;
`;

const SignUpWrapper = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 18px;
`;

const UnderLineText = styled(Link)`
  font-size: 12px;
  line-height: 13px;
  color: #868e96;
  text-decoration: none;

  &:hover {
    color: #d9480f;
  }
`;

const RegisterText = styled(UnderLineText)`
  font-weight: bold;
`;

export default inject("user")(observer(Login));
