import axios from 'axios';
import { action, computed, flow, observable } from 'mobx';

import Axios from '../axios';
import apiServerPath from '../config/config';
import { BookType, RecommendType, UsedBookType } from '../config/types';
import UtilStore from './util';

interface Search {
  count: number;
  data: BookType[];
}

interface UsedSearch {
  count: number;
  data: UsedBookType[];
}

type SearchType = {
  bookName: string;
  size: number;
  next?: number | 0;
};

axios.defaults.baseURL = apiServerPath;
axios.defaults.withCredentials = true;

export default class SearchStore {
  @observable popularBookList: RecommendType[] = [];
  @observable popularBooks: RecommendType[] = [];

  @observable
  search: Search = {
    count: -1,
    data: []
  };

  @observable
  usedSearch: UsedSearch = {
    count: -1,
    data: []
  };

  @observable more: number = 0;

  @observable fetching: boolean = false;

  @observable bookName: string = "";
  @observable page = 1;

  utilStore = UtilStore.getInstance();

  @action
  fetchPopulars = flow(function* (this: SearchStore) {
    if (!this.popularBookList.length) {
      this.utilStore.setFetching(true);
      const response = yield Axios.get(`/bibly/api?cmd=popular_books`);
      this.utilStore.setFetching(false);

      if (response.status === 200) {
        this.popularBookList = response.data.list;
        this.popularBooks = this.popularBookList.slice(0, 30);
      }
    }
  });

  @action
  fetchMorePopulars = () => {
    const { page } = this;
    this.popularBooks = [...this.popularBooks, ...this.popularBookList.slice(page * 30, (page + 1) * 30)];
    this.page = this.page + 1;
  };

  @action
  searchBooks = flow(function* (this: SearchStore, data: SearchType) {
    this.utilStore.setFetching(true);
    const response = yield axios.post(`/searchBooks`, data);

    if (response.status === 200) {
      if (response.data.return.length === 1) {
        this.search = {
          count: 0,
          data: []
        };
      } else if (response.data.return[0]) {
        this.search = {
          count: response.data.return[0],
          data: response.data.return.slice(1, response.data.return.length)
        };

        this.more = response.data.return[response.data.return.length - 1].sort;
      }
    }

    this.utilStore.setFetching(false);
  });

  @action
  searchMoreBooks = flow(function* (this: SearchStore, data: SearchType) {
    this.fetching = true;

    const response = yield axios.post(`/searchBooks`, data);

    if (response.status === 200) {
      if (response.data.return[0]) {
        this.search = {
          ...this.search,
          data: [...this.search.data, ...response.data.return.slice(1, response.data.return.length)]
        };
        this.more = response.data.return[response.data.return.length - 1].sort;
      }
    }
    this.fetching = false;
  });

  @action
  searchUsedBooks = flow(function* (this: SearchStore, bookName: string) {
    this.utilStore.setFetching(true);
    const response = yield axios.get(`/bibly/api?cmd=used_book_list&query=${bookName}`);
    this.utilStore.setFetching(false);

    if (response.status === 200) {
      this.usedSearch = {
        count: response.data.list.length,
        data: response.data.list
      };
    }
  });

  @action
  setBookName(bookName: string) {
    this.bookName = bookName;
  }

  @computed
  get fetchMore() {
    return this.search.count !== this.search.data.length;
  }

  @computed
  get isFetching() {
    return this.fetching;
  }

  @computed
  get fetchPopularMore() {
    return this.popularBookList.length !== this.popularBooks.length;
  }
}
