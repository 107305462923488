import { inject, observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

import UtilStore from '../../stores/util';

const Spinner = () => {
  const utilStore = useMemo(() => {
    return UtilStore.getInstance();
  }, []);

  return (
    <Wrapper active={utilStore.fetching}>
      <ClipLoader size={50} color={"#000"} />
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.active ? "flex" : "none")};
  z-index: 3;
`;

export default inject("util")(observer(Spinner));
