import React from 'react';

interface Props {
  color: string;
}

const RightArrow: React.FC<Props> = (props) => {
  const { color, ...restProps } = props;

  const cls1 = {
    fill: "none"
  };

  const cls2 = {
    fill: "none",
    stroke: color,
    strokeLineCap: "round",
    strokeLineJoin: "round",
    strokeWidth: "2px"
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 24 20" {...restProps}>
      <g id="btn_main_arrow" transform="translate(1924.786 2744.233)">
        <path
          id="사각형_492"
          d="M0 0h24v20H0z"
          style={cls1}
          data-name="사각형 492"
          transform="translate(-1924.786 -2744.233)"
        />
        <path id="선_17" d="M0 0h13.965" style={cls2} data-name="선 17" transform="translate(-1920.286 -2734.014)" />
        <path
          id="패스_673"
          d="M-1897.993-2740.093l6.079 6.079-6.079 6.079"
          style={cls2}
          data-name="패스 673"
          transform="translate(-13.046)"
        />
      </g>
    </svg>
  );
};

RightArrow.defaultProps = {
  color: "#404041"
};

export default RightArrow;
