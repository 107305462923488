import BannerStore from './banner';
import BasketStore from './basket';
import BestStore from './best';
import DeliveryStore from './delivery';
import MemberStore from './member';
import OrderStore from './order';
import RegisterStore from './register';
import SearchStore from './search';
import UsedStore from './used';
import UserStore from './user';
import UtilStore from './util';

export default {
  basket: new BasketStore(),
  member: new MemberStore(),
  util: UtilStore.getInstance(),
  search: new SearchStore(),
  order: new OrderStore(),
  delivery: new DeliveryStore(),
  used: new UsedStore(),
  best: new BestStore(),
  user: new UserStore(),
  register: new RegisterStore(),
  banner: new BannerStore()
};
