import React, { useEffect, useMemo, useState } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import UserStore from '../stores/user';
import UtilStore from '../stores/util';

interface Props extends RouteProps {
  component: any;
  loginRequired: boolean;
}

declare global {
  interface Window {
    biblyTrackingSend: any;
  }
}

window.biblyTrackingSend = window.biblyTrackingSend || {};

const SessionCheckLayout = ({ component: Component, loginRequired, ...restProps }: Props) => {
  const [fetched, setFetched] = useState(false);

  const history = useHistory();

  const utilStore = useMemo(() => {
    return UtilStore.getInstance();
  }, []);

  const userStore = useMemo(() => {
    return UserStore.getInstance();
  }, []);

  useEffect(() => {
    async function sessionCheck() {
      await userStore.sessionCheck();
      setFetched(true);
    }

    sessionCheck();
  }, [userStore]);

  useEffect(() => {
    if (fetched) {
      if (!userStore.isLogin && loginRequired) {
        utilStore.addToolTips("로그인이 필요한 서비스입니다.");
        history.push("/login");
      }
    }
  }, [fetched, history, loginRequired, userStore.isLogin, utilStore]);

  /** 트래킹 코드 */

  useEffect(() => {
    setTimeout(function () {
      window.biblyTrackingSend({ service: "찾서", behavior: "pageView" });
    }, 1000);

    window.scrollTo(0, 0);

    return history.listen(() => {
      setTimeout(function () {
        window.biblyTrackingSend({ service: "찾서", behavior: "pageView" });
      }, 1000);
    });
  }, [history]);

  return <Route {...restProps} render={(props) => (fetched ? <Component {...props} /> : <></>)} />;
};

SessionCheckLayout.defaultProps = {
  loginRequired: false
};

export default SessionCheckLayout;
