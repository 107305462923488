import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import BackBtn from '../assets/icons/back.png';
import Axios from '../axios';
import { CategoryBook } from '../components/CategoryBook';
import Divider from '../components/Divider';
import Footer from '../components/Footer';
import FooterInfo from '../components/FooterInfo';
import apiServerPath from '../config/config';
import { BestBookType } from '../config/types';
import BasketStore from '../stores/basket';
import UtilStore from '../stores/util';

const CategorySearch = () => {
  const [data, setData] = useState<BestBookType[]>([]);
  const [active, setActive] = useState(false);

  const basketStore = useMemo(() => {
    return BasketStore.getInstance();
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const title = useMemo(() => {
    return query.get("category");
  }, [query]);

  const categoryId = useMemo(() => {
    return query.get("category_id");
  }, [query]);

  const handleBack = useCallback(() => {
    window.history.back();
  }, []);

  const utilStore = useMemo(() => {
    return UtilStore.getInstance();
  }, []);

  const categoryBookList = useMemo(() => {
    return data.map((el: BestBookType, index) => {
      const isStored = basketStore.baskets.find((element) => el.book_id === element.bookId) !== undefined;

      return (
        <>
          <CategoryBook isStored={isStored} key={index} data={el} index={index} categoryId={categoryId!} />
          <Divider />
        </>
      );
    });
  }, [data, basketStore.baskets, categoryId]);

  const toggleFooter = useCallback(() => {
    setActive(!active);
  }, [active]);

  const fetchData = useCallback(async () => {
    utilStore.setFetching(true);
    const response = await Axios.get(`${apiServerPath}/bibly/api?cmd=category_book_list&category_id=${categoryId}`);
    utilStore.setFetching(false);
    if (response.status === 200) {
      setData(response.data.list);
    }
  }, [categoryId, utilStore]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container>
      <Header>
        <ImageBtn src={BackBtn} alt="back" onClick={handleBack} />
        <Title>{title}</Title>
      </Header>
      <Content>{categoryBookList}</Content>
      <Footer active={active} toggleFooter={toggleFooter} />
      <FooterInfo style={{ marginBottom: "3.5rem" }} />
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 100vh;
`;

const Content = styled("div")`
  padding: 0 2rem;
`;

const Header = styled("div")`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  font-size: 22px;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
`;

const Title = styled("div")`
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
`;

const ImageBtn = styled("img")`
  height: 20px;
  cursor: pointer;
`;

export default inject("basket")(observer(CategorySearch));
