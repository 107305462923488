import axios from 'axios';
import { action, computed, flow, observable } from 'mobx';

import apiServerPath from '../config/config';
import { UsedBookType } from '../config/types';
import UtilStore from './util';

axios.defaults.baseURL = apiServerPath;
axios.defaults.withCredentials = true;

export default class UsedStore {
  @observable usedBookList: UsedBookType[] = [];
  @observable usedBooks: UsedBookType[] = [];
  @observable page = 1;

  utilStore = UtilStore.getInstance();

  @action
  fetchUsedBooks = flow(function* (this: UsedStore) {
    if (!this.usedBookList.length) {
      this.utilStore.setFetching(true);
      const response = yield axios.get(`/bibly/api?cmd=used_book_list`);

      this.utilStore.setFetching(false);

      if (response.status === 200) {
        this.usedBookList = response.data.list;
        this.usedBooks = this.usedBookList.slice(0, 30);
      }
    }
  });

  @action
  fetchMoreUsedBooks = () => {
    const { page } = this;
    this.usedBooks = [...this.usedBooks, ...this.usedBookList.slice(page * 30, (page + 1) * 30)];
    this.page = this.page + 1;
  };

  @computed
  get fetchMore() {
    return this.usedBookList.length > this.usedBooks.length;
  }
}
