import Axios from 'axios';

import apiServerPath from '../config/config';
import UtilStore from '../stores/util';

const utilStore = UtilStore.getInstance();

const axios = Axios.create({
  baseURL: apiServerPath,
  withCredentials: true
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 500) {
      if (error.response.data.msg) {
        utilStore.addToolTips(error.response.data.msg);
      } else if (error.response.data.err_msg) {
        utilStore.addToolTips(error.response.data.err_msg);
      } else {
        utilStore.addToolTips("서버에서 에러가 발생했습니다.");
      }
    }
    return error;
  }
);

export default axios;
