import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../Button';

interface Props {
  title: string;
  description: string;
  routes: string;
  style?: React.CSSProperties;
}

export const FindPasswordType: React.FC<Props> = (props) => {
  const { title, description, routes, style } = props;

  return (
    <Wrapper style={style}>
      <InfoWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </InfoWrapper>
      <Link style={{ marginLeft: "auto" }} to={routes}>
        <StyledButton value="인증하기" variant="dark" />
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  height: 96px;
  padding: 24px 32px;
  align-items: center;

  @media (max-width: 408px) {
    padding: 24px;
  }
`;

const InfoWrapper = styled("div")``;

const Title = styled("h4")`
  margin: 0;
`;

const Description = styled("p")`
  color: #868e96;
  font-size: 13px;
  margin: 8px 0px 0px;
  /* margin: 0; */
`;

const StyledButton = styled(Button)`
  border-radius: 0;
`;
