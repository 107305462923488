import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DownloadUnFillBtn from '../../assets/icons/btn_download.png';
import DownloadFillBtn from '../../assets/icons/btn_download_fill.png';
import { BestBookType } from '../../config/types';
import lib from '../../lib/library';
import BasketStore from '../../stores/basket';
import { Thumbnail } from '../Thumbnail';

interface Props {
  isStored: boolean;
  data: BestBookType;
  index: number;
  categoryId: string;
}

export const CategoryBook = (props: Props) => {
  const { book_id, book_name, author_name, publisher_name, price, discount_rate, img_url } = props.data;

  const { isStored, index, categoryId } = props;

  const basketStore = useMemo(() => {
    return BasketStore.getInstance();
  }, []);

  const downloadBtn = useMemo(() => {
    return isStored ? DownloadFillBtn : DownloadUnFillBtn;
  }, [isStored]);

  const realPrice = useMemo(() => {
    return lib.numberCommaFormat(price) + "원";
  }, [price]);

  const discountPrice = useMemo(() => {
    return lib.numberCommaFormat(price * (1 - discount_rate)) + "원";
  }, [price, discount_rate]);

  const handleBasket = useCallback(() => {
    const data = {
      bookName: book_name,
      authorName: author_name,
      publisherName: publisher_name,
      bookId: book_id,
      imagePath: img_url,
      book_condition: 0
    };

    const query = {
      type: "main_best",
      rank: index
    };

    basketStore.handleBasket(data, false, query);
  }, [book_name, author_name, publisher_name, book_id, img_url, basketStore, index]);

  const redirectUrl = useMemo(() => {
    return `/detail/${book_id}?btrListType=main_cate&btrRank=${index}&btrCateId=${categoryId}`;
  }, [book_id, index, categoryId]);

  return (
    <Wrapper>
      <Link to={redirectUrl}>
        <Thumbnail src={img_url} />
      </Link>
      <BookDetail>
        <Link to={redirectUrl}>
          <BookTitle>{book_name}</BookTitle>
        </Link>
        <BookInfo>{author_name}</BookInfo>
        <BookInfo>{publisher_name}</BookInfo>

        <RealPrice>{realPrice}</RealPrice>
        <DiscountPrice>
          {" "}
          {">"} {discountPrice}
        </DiscountPrice>
      </BookDetail>

      <IconArea>
        <Icon src={downloadBtn} alt="download" onClick={handleBasket} />
      </IconArea>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  width: 100%;
  min-height: 132px;
`;

const BookDetail = styled("div")`
  padding-left: 1rem;
  flex: 9;
`;

const BookInfo = styled("p")`
  color: #999999;
  font-size: 13px;
`;

const BookTitle = styled("a")`
  margin: 0;
  padding-top: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  word-break: keep-all;
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const RealPrice = styled("span")`
  color: #999999;
  font-size: 13px;
  text-decoration: line-through;
`;

const DiscountPrice = styled("span")`
  color: #999999;
  font-size: 13px;
`;

const IconArea = styled("div")`
  display: flex;
  align-items: flex-end;
  flex: 1;
`;

const Icon = styled("img")`
  cursor: pointer;
`;
