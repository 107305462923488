import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CHARACTER from '../assets/character/CHARACTER.png';
import { Button } from '../components/Button';

const NotFoundPage = () => {
  const history = useHistory();

  const handleRedirect = useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <Container>
      <Title>비정상적인 접근입니다.</Title>
      <div>입력하신 페이지를 다시 확인해주세요.</div>
      <Button style={{ marginTop: 18 }} onClick={handleRedirect} value="홈으로"></Button>
      <Character src={CHARACTER} />
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #495057;

  @media (max-height: 700px) {
    justify-content: flex-start;
    padding-top: 2rem;
  }
`;

const Title = styled("h2")`
  font-size: 24px;
`;

const Character = styled("img")`
  width: 300px;
  position: absolute;
  bottom: 0;
  align-self: flex-end;
`;

export default NotFoundPage;
