import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Cookies, ReactCookieProps, withCookies } from 'react-cookie';
import styled from 'styled-components';

import CLOSEBTN from '../../assets/icons/closebtn.png';
import POPUP from '../../assets/popup.jpg';
import PopUpDC from '../../config/popup';

interface Props extends ReactCookieProps {}

const EventPopup: React.FC<Props> = (props) => {
  const [cookies, setCookies] = useState<Cookies | undefined>(props.cookies);

  const [show, setShow] = useState(false);

  const showModal = useCallback(() => {
    setShow(true);
  }, []);

  const closeModal = useCallback(() => {
    sessionStorage.setItem("opened_popup", "true");

    setShow(false);
  }, []);

  const closePopUp = useCallback(() => {
    if (cookies) {
      const expires: Date = moment().add(1, "year").toDate();
      cookies.set(PopUpDC.COOKIE_VALUE, true, { path: "/", expires });
    }

    closeModal();
  }, [cookies, closeModal]);

  const handleChildClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  const handleDetail = useCallback(() => {
    window.open("https://blog.naver.com/bibly_book/221850328125");
  }, []);

  useEffect(() => {
    if (cookies) {
      const currentCookies = cookies.get(PopUpDC.COOKIE_VALUE);

      if (!sessionStorage.getItem("opened_popup")) {
        setShow(!currentCookies);
      }
    } else {
      setCookies(props.cookies);
    }
  }, [props.cookies, cookies, showModal]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [show]);

  return (
    <Wrapper onClick={closeModal} opened={show}>
      <Content onClick={handleChildClick}>
        <Thumbnail onClick={handleDetail} src={POPUP}></Thumbnail>
        <CloseBtn src={CLOSEBTN} alt="closebtn" onClick={closeModal} />
        <DeleteBtn onClick={closePopUp}>다시 보지 않기</DeleteBtn>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ opened: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  opacity: ${(props) => (props.opened ? "1" : "0")};
  visibility: ${(props) => (props.opened ? "visible" : "hidden")};
  transition: 0.25s;
`;

const Content = styled("div")`
  position: relative;
`;

const CloseBtn = styled("img")`
  width: 28px;
  height: 28px;
  top: 16px;
  right: 16px;
  cursor: pointer;
  position: absolute;
`;

const Thumbnail = styled("img")`
  max-width: auto;
  height: 660px;
  cursor: pointer;

  @media (max-height: 700px) {
    height: 500px;
  }
`;

const DeleteBtn = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  color: #ced4da;
  bottom: 0;
  background-color: #222222;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding: 8px 0px;
  cursor: pointer;
`;

export default withCookies(EventPopup);
