import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DownloadUnFillBtn from '../assets/icons/btn_download.png';
import DownloadFillBtn from '../assets/icons/btn_download_fill.png';
import { Thumbnail } from '../components/Thumbnail';
import { BookType } from '../config/types';
import lib from '../lib/library';
import BasketStore from '../stores/basket';

interface Props {
  data: BookType;
  keyword: string;
  index: number;
  isStored: boolean;
}

const Book = (props: Props) => {
  const {
    authorName,
    bookName,
    publisherName,
    bookId,
    price,
    discount_rate,
    imgUrl,
    selling_status
  } = props.data._source;

  const { isStored, keyword, index } = props;

  const basketStore = useMemo(() => {
    return BasketStore.getInstance();
  }, []);

  const downloadBtn = useMemo(() => {
    return isStored ? DownloadFillBtn : DownloadUnFillBtn;
  }, [isStored]);

  const handleSelected = useCallback(() => {
    const { bookName, authorName, publisherName, bookId, imgUrl } = props.data._source;

    const data = {
      bookName: bookName,
      authorName: authorName,
      publisherName: publisherName,
      bookId: bookId,
      imagePath: imgUrl,
      book_condition: 0
    };

    const query = {
      type: "search",
      rank: index
    };

    basketStore.handleBasket(data, false, query);
  }, [basketStore, props.data._source, index]);

  const realPrice = useMemo(() => {
    return lib.numberCommaFormat(price) + "원";
  }, [price]);

  const discountPrice = useMemo(() => {
    return lib.numberCommaFormat(price * (1 - discount_rate)) + "원";
  }, [price, discount_rate]);

  const soldoutWrapper = useMemo(() => {
    return (
      selling_status !== 1 && (
        <Link to={`/detail/${bookId}?btrListType=search&btrRank=${index}&q=${keyword}`}>
          <SoldOutWrapper>
            <span>SOLD OUT</span>
          </SoldOutWrapper>
        </Link>
      )
    );
  }, [selling_status, bookId, index, keyword]);

  return (
    <Wrapper>
      {soldoutWrapper}
      <Link to={`/detail/${bookId}?btrListType=search&btrRank=${index}&q=${keyword}`}>
        <Thumbnail src={imgUrl} />
      </Link>
      <BookDetail>
        <Link to={`/detail/${bookId}?btrListType=search&btrRank=${index}&q=${keyword}`}>
          <BookTitle>{bookName}</BookTitle>
        </Link>

        <BookInfo>{authorName}</BookInfo>
        <BookInfo>{publisherName}</BookInfo>
        <RealPrice>{realPrice}</RealPrice>
        <DiscountPrice>
          {" "}
          {">"} {discountPrice}
        </DiscountPrice>
      </BookDetail>

      <IconArea onClick={handleSelected}>
        <Icon src={downloadBtn} alt="download" />
      </IconArea>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  width: 100%;
  min-height: 132px;
  z-index: 10;
  position: relative;
`;

const BookDetail = styled("div")`
  padding-left: 1rem;
  flex: 9;
`;

const BookInfo = styled("p")`
  color: #999999;
  font-size: 13px;
`;

const BookTitle = styled("p")`
  margin: 0;
  padding-top: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  word-break: keep-all;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const RealPrice = styled("span")`
  color: #999999;
  font-size: 13px;
  text-decoration: line-through;
`;

const DiscountPrice = styled("span")`
  color: #999999;
  font-size: 13px;
`;

const IconArea = styled("div")`
  display: flex;
  align-items: flex-end;
  flex: 1;
`;

const Icon = styled("img")`
  cursor: pointer;
`;

const SoldOutWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #adb5bd;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
`;

export default Book;
