import React from "react";
import styled from "styled-components";
import lib from "../lib/library";

interface Props {
  label: string;
  editable?: boolean;
  value?: string;
  name: string;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  warning?: string;
  type?: string;
}

const Label = styled("p")`
  font-size: 0.875rem;
  color: #666666;
`;

const LabelInput: React.FC<Props> = (props) => {
  const { label, name, value, editable, onClick, onChange, onBlur, warning, type } = props;

  return (
    <div>
      <Label>{label}</Label>
      <Input
        name={name}
        readOnly={!editable}
        onClick={onClick}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={`${lib.getJosa(label)} 입력해주세요.`}
        type={type}
      />
      <Warning>{warning}</Warning>
    </div>
  );
};

const Input = styled("input")`
  border: none;
  outline: none;
  font-size: 1rem;
  border-bottom: 2px solid ${(props) => (props.value ? "#222222" : "#f2f3f5")};
  padding: 0.75rem 0;
  font-weight: 500;
  width: 100%;
  cursor: ${(props) => (props.readOnly ? "pointer" : "auto")};
  margin-bottom: 8px;
  border-radius: 0px;
`;

const Warning = styled("span")`
  font-size: 14px;
  font-weight: bold;
  color: #f03e3e;
`;

LabelInput.defaultProps = {
  editable: true
};

export default LabelInput;
