import axios from 'axios';
import { action, computed, flow, observable } from 'mobx';

import Axios from '../axios';
import apiServerPath from '../config/config';
import UtilStore from './util';

type UserType = {
  customer_id: number;
  customer_name: string;
  mileage: number;
  order_count: number;
};

type BasketResponseType = {
  author_name: string;
  book_condition: number;
  book_id: number;
  book_name: string;
  book_sub_name: string;
  cart_id: number;
  image_url: string;
  order_quanity: number;
  publish_dt: string;
  publisher_name: string;
  user_id: number;
};

axios.defaults.baseURL = apiServerPath;
axios.defaults.withCredentials = true;

export default class UserStore {
  static instance: UserStore | null = null;

  static getInstance() {
    if (UserStore.instance === null) {
      UserStore.instance = new UserStore();
    }
    return UserStore.instance;
  }

  utilStore = UtilStore.getInstance();

  @observable
  userInfo: UserType | null = null;

  @action
  login = flow(function* (this: UserStore, email: string, user_password: string) {
    this.utilStore.setFetching(true);

    try {
      const response = yield Axios.get(`/bibly/api?cmd=login&email=${email}&user_password=${user_password}`);

      if (response.status === 200) {
        this.userInfo = response.data.user_info;

        sessionStorage.setItem("userInfo", JSON.stringify(response.data.user_info));

        const books = sessionStorage.getItem("books");

        if (books) {
          const res = yield axios.post(`/bibly/api`, {
            cmd: "cart_put",
            cart_list: JSON.parse(books)
          });

          if (res.status === 200) {
            const data = res.data.list.map((el: BasketResponseType) => ({
              authorName: el.author_name,
              bookId: el.book_id,
              bookName: el.book_name,
              book_condition: el.book_condition,
              imagePath: el.image_url,
              publisherName: el.publisher_name
            }));

            sessionStorage.setItem("books", JSON.stringify(data));
          }
        } else {
          const res = yield axios.get(`/bibly/api?cmd=cart_get`);

          if (res.status === 200) {
            const data = res.data.list.map((el: BasketResponseType) => ({
              authorName: el.author_name,
              bookId: el.book_id,
              bookName: el.book_name,
              book_condition: el.book_condition,
              imagePath: el.image_url,
              publisherName: el.publisher_name
            }));

            sessionStorage.setItem("books", JSON.stringify(data));
          }
        }
      }

      this.utilStore.setFetching(false);
      return response;
    } catch (err) {
      console.error(err);
    }
  });

  @action
  validateEmail = flow(function* (this: UserStore, email: string) {
    try {
      const response = yield axios.get(`/bibly/api?cmd=sign_up_1_duplicate_id_check&email=${email}`);
      if (response.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  });

  @action
  sessionCheck = flow(function* (this: UserStore) {
    try {
      const response = yield axios.get(`/bibly/api?cmd=session_check`);

      if (response.status === 200) {
        if (response.data.user_info.cart) {
          sessionStorage.setItem("books", JSON.stringify(this.cartParser(response.data.user_info.cart)));
        }

        sessionStorage.setItem("userInfo", JSON.stringify(response.data.user_info));
        this.userInfo = response.data.user_info;
      }

      sessionStorage.setItem("session_chk", "checked");
    } catch (err) {
      sessionStorage.setItem("session_chk", "checked");
    }
  });

  @action
  logout = flow(function* (this: UserStore) {
    if (this.userInfo) {
      const response = yield axios.get(`/bibly/api?cmd=logout`);
      if (response.status === 200) {
        this.userInfo = null;
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("books");
        this.utilStore.addToolTips("로그아웃되었습니다.");
      }
    }
  });

  @action
  authPhoneForEmail = flow(function* (this: UserStore, user_phone: string) {
    this.utilStore.setFetching(true);
    const response = yield Axios.get(`${apiServerPath}/bibly/api?cmd=find_id_1_send_token&user_phone=${user_phone}`);
    this.utilStore.setFetching(false);
    return response;
  });

  @action
  checkNumberForEmail = flow(function* (
    this: UserStore,
    user_phone: string,
    certification_number: string,
    token: string
  ) {
    this.utilStore.setFetching(true);
    const response = yield Axios.get(
      `${apiServerPath}/bibly/api?cmd=find_id_2_complete&user_phone=${user_phone}&certification_number=${certification_number}&token=${token}`
    );

    this.utilStore.setFetching(false);
    return response;
  });

  @action
  authPhoneForPassword = flow(function* (this: UserStore, user_phone: string) {
    this.utilStore.setFetching(true);
    const response = yield Axios.get(
      `${apiServerPath}/bibly/api?cmd=find_password_phone_1_send_token&user_phone=${user_phone}`
    );
    this.utilStore.setFetching(false);
    return response;
  });

  @action
  checkNumberForPassword = flow(function* (
    this: UserStore,
    user_email: string,
    user_phone: string,
    certification_number: string,
    token: string
  ) {
    this.utilStore.setFetching(true);
    const response = yield Axios.get(
      `${apiServerPath}/bibly/api?cmd=find_password_phone_2_token_check&email=${user_email}&user_phone=${user_phone}&certification_number=${certification_number}&token=${token}`
    );

    this.utilStore.setFetching(false);
    return response;
  });

  @action
  changePassword = flow(function* (this: UserStore, email: string, user_phone: string, user_password: string) {
    this.utilStore.setFetching(true);
    const response = yield Axios.get(
      `${apiServerPath}/bibly/api?cmd=find_password_phone_3_complete&email=${email}&user_phone=${user_phone}&user_password=${user_password}`
    );

    this.utilStore.setFetching(false);

    if (response.status === 200) {
      this.utilStore.addToolTips("비밀번호가 변경되었습니다.");
    }
    return response;
  });

  @action
  sendEmailForPassword = flow(function* (this: UserStore, email: string) {
    this.utilStore.setFetching(true);
    const response = yield Axios.get(`${apiServerPath}/bibly/api?cmd=find_password_email&email=${email}`);
    this.utilStore.setFetching(false);
    if (response.status === 200) {
      this.utilStore.addToolTips("이메일을 재설정할수 있는 링크를 보내드렸어요.");
    }
  });

  @action
  authToken = flow(function* (token: string) {
    try {
      const response = yield axios.get(`/bibly/api?cmd=password_reset_1_token_check&token=${token}`, {
        withCredentials: true
      });
      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      return false;
    }
  });

  cartParser = (data: any) => {
    return data.map((el: any) => ({
      authorName: el.author_name,
      bookId: el.book_id,
      bookName: el.book_name,
      book_condition: el.book_condition,
      imagePath: el.image_url,
      publisherName: el.publisher_name
    }));
  };

  @action
  changePasswordByEmail = flow(function* (this: UserStore, token: string, password: string) {
    this.utilStore.setFetching(true);
    const response = yield Axios.get(
      `${apiServerPath}/bibly/api?cmd=password_reset_2_complete&token=${token}&user_password=${password}`
    );

    this.utilStore.setFetching(false);

    if (response.status === 200) {
      this.utilStore.addToolTips("비밀번호를 변경했습니다.");
    }

    return response;
  });

  @computed
  get isLogin() {
    return !!this.userInfo;
  }
}
