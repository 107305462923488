import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DownloadUnFillBtn from '../assets/icons/btn_download.png';
import DownloadFillBtn from '../assets/icons/btn_download_fill.png';
import { Thumbnail } from '../components/Thumbnail';
import { RecommendType } from '../config/types';
import lib from '../lib/library';
import BasketStore from '../stores/basket';

interface Props {
  isStored: boolean;
  data: RecommendType;
  index: number;
}

const RecommendBook = (props: Props) => {
  const { book_id, book_name, book_sub_name, image_url, price, discount_rate } = props.data;

  const { isStored, index } = props;

  const basketStore = useMemo(() => {
    return BasketStore.getInstance();
  }, []);

  const downloadBtn = useMemo(() => {
    return isStored ? DownloadFillBtn : DownloadUnFillBtn;
  }, [isStored]);

  const handleSelected = useCallback(() => {
    const { book_name, author_name, book_id, publisher_name, image_url } = props.data;

    const data = {
      bookName: book_name,
      authorName: author_name,
      publisherName: publisher_name,
      bookId: book_id,
      imagePath: image_url,
      book_condition: 0
    };

    const query = {
      type: "main_bpick",
      rank: index
    };

    basketStore.handleBasket(data, false, query);
  }, [props.data, basketStore, index]);

  const realPrice = useMemo(() => {
    return lib.numberCommaFormat(price) + "원";
  }, [price]);

  const discountPrice = useMemo(() => {
    return lib.numberCommaFormat(price * (1 - discount_rate)) + "원";
  }, [price, discount_rate]);

  const redirectUrl = useMemo(() => {
    return `/detail/${book_id}?btrListType=main_bpick&btrRank=${index}`;
  }, [book_id, index]);

  return (
    <Wrapper>
      <Link to={redirectUrl}>
        <Thumbnail src={image_url} />
      </Link>

      <BookDetail>
        <BookTitle to={redirectUrl}>{book_name}</BookTitle>
        <BookSubName>{book_sub_name}</BookSubName>
        <PriceInfo>
          <RealPrice>{realPrice}</RealPrice>
          <DiscountPrice>
            {" "}
            {">"} {discountPrice}
          </DiscountPrice>
        </PriceInfo>
      </BookDetail>

      <IconArea onClick={handleSelected}>
        <Icon src={downloadBtn} alt="download" />
      </IconArea>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  width: 100%;
  min-height: 132px;
`;

const BookDetail = styled("div")`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  flex: 9;
`;

const BookTitle = styled(Link)`
  margin: 0;
  padding-top: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  word-break: keep-all;
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const BookSubName = styled("p")`
  color: #999999;
  font-size: 13px;
  -webkit-line-clamp: 2;
`;

const IconArea = styled("div")`
  display: flex;
  align-items: flex-end;
  flex: 1;
`;

const Icon = styled("img")`
  cursor: pointer;
`;

const PriceInfo = styled("div")`
  margin-top: auto;
`;

const RealPrice = styled("span")`
  color: #999999;
  font-size: 13px;
  text-decoration: line-through;
`;

const DiscountPrice = styled("span")`
  color: #999999;
  font-size: 13px;
`;

export default RecommendBook;
