import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import downArrow from '../assets/icons/down_arrow.png';

const TopButton = () => {
  const [scrollY, setScrollY] = useState<number>(0);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onScroll = () => {
    setScrollY(window.scrollY);
  };

  const handleTop = () => {
    window.scrollTo(0, 0);
  };

  const show = useMemo(() => {
    return scrollY > 0;
  }, [scrollY]);

  return (
    <Wrapper show={show} onClick={handleTop}>
      <Image src={downArrow} />
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 16px;
  border-radius: 5px;
  cursor: pointer;
  bottom: 82px;
  right: 16px;
  z-index: 99;

  @media (min-width: 420px) {
    right: 48px;
  }
`;

const Image = styled("img")`
  transform: rotate(180deg);
`;

export default TopButton;
