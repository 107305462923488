import axios from 'axios';
import { action, flow, observable } from 'mobx';

import Axios from '../axios';
import apiServerPath from '../config/config';
import UtilStore from './util';

axios.defaults = {
  baseURL: apiServerPath,
  withCredentials: true
};

type Orders = {
  bookList: string;
  buyer_name: string;
  buyer_phone: string;
  receiver_name: string;
  receiver_phone: string;
  road: string;
  road_detail: string;
  receiver_requirement: string;
  privae_chk: number;
  bibly_YN: number;
  user_id: string;
  CardNo?: string;
  ExpMonth?: string;
  ExpYear?: string;
  IDNo?: string;
  CardPw?: string;
  card_id?: number;
};

type UserOrderType = {
  address1: string;
  address2: string;
  address_id: number;
  card_company: string;
  card_id: number;
  card_num: string;
  customer_id: number;
  customer_name: string;
  customer_phone: string;
  email: string;
  mileage: number;
  receiver_name: string;
  shipping_phone: string;
  shipping_request_detail: string;
};

type keyType = {
  [key: string]: any;
};

export default class OrderStore {
  utilStore = UtilStore.getInstance();

  @observable
  userOrderData: keyType = {
    address1: "",
    address2: "",
    address_id: 0,
    card_company: "",
    card_id: 0,
    card_num: "",
    customer_id: 0,
    customer_name: "",
    customer_phone: "",
    email: "",
    mileage: 0,
    receiver_name: "",
    shipping_phone: "",
    shipping_request_detail: ""
  };

  @action
  fetchData = flow(function* (this: OrderStore) {
    try {
      this.utilStore.setFetching(true);
      const response = yield axios.get(`/bibly/api?cmd=get_user_order_info`);

      if (response.status === 200) {
        this.userOrderData = response.data.list;
      }
    } catch (err) {
      return {};
    } finally {
      this.utilStore.setFetching(false);
    }
  });

  @action
  orderBooks = flow(function* (this: OrderStore, data: Orders) {
    try {
      this.utilStore.setFetching(true);
      const response = yield Axios.post(`/purchaseSuccess`, data);
      this.utilStore.setFetching(false);

      return response;
    } catch (err) {
      return false;
    }
  });
}
