import React, { useCallback, useEffect, useMemo } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import clipBoard from "../assets/icons/clipboard.png";
import downArrow from "../assets/icons/down_arrow.png";
import config from "../config";
import BasketStore from "../stores/basket";
import UserStore from "../stores/user";
import UtilStore from "../stores/util";
import BookItem from "./BookItem";

interface Props {
  active: boolean;
  toggleFooter: () => void;
}

const Footer = (props: Props) => {
  const { toggleFooter, active } = props;

  const basketStore = useMemo(() => {
    return BasketStore.getInstance();
  }, []);

  const history = useHistory();

  useEffect(() => {
    basketStore.init();
  }, [basketStore]);

  const handleChildClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
    },
    []
  );

  const handleDelete = useCallback(
    (id: number, book_condition: number) => {
      basketStore.spliceBasket(id, book_condition);
    },
    [basketStore]
  );

  const headerText = useMemo(() => {
    return basketStore.count
      ? `${basketStore.count}권 선택완료`
      : "책을 담아주세요.";
  }, [basketStore.count]);

  const userStore = useMemo(() => {
    return UserStore.getInstance();
  }, []);

  const utilStore = useMemo(() => {
    return UtilStore.getInstance();
  }, []);

  const handleRedirect = useCallback(() => {
    if (!userStore.isLogin) {
      utilStore.addToolTips("로그인이 필요한 서비스입니다.");
      history.push("/login");
      return;
    } else if (basketStore.count < 1) {
      utilStore.addToolTips(`책을 최소 1권은 담아주세요.`);
      return;
    }
    history.push("/order");
  }, [basketStore.count, history, userStore.isLogin, utilStore]);

  const basketBooks = useMemo(() => {
    return config.books.map((el: number) => {
      const data = basketStore.findBasket(el);
      return <BookItem key={el} id={el} data={data} onDelete={handleDelete} />;
    });
  }, [handleDelete, basketStore.baskets]);

  const basketUrl = useMemo(() => {
    const items = basketStore.baskets;
    const bookId = items?.map((el) => el.bookId);
    return `https://go.bibly.kr?bookId=${bookId?.toString()}`;
  }, [basketStore.baskets]);

  const onCopy = useCallback(() => {
    utilStore.addToolTips("클립보드에 복사되었습니다.");
  }, [utilStore]);

  const clipBoardBtn = useMemo(() => {
    if (active && basketStore.count)
      return (
        <CopyToClipboard text={basketUrl} onCopy={onCopy}>
          <ClipBoard
            src={clipBoard}
            alt="clipboard"
            onClick={handleChildClick}
          />
        </CopyToClipboard>
      );
    else return;
  }, [active, basketStore.count, basketUrl, onCopy, handleChildClick]);

  return (
    <Wrapper onClick={toggleFooter} active={false}>
      <Dialog onClick={handleChildClick}>
        <div onClick={toggleFooter}>
          <ButtonWrapper>
            <ButtonText src={downArrow} active={false}></ButtonText>
            <Button />
          </ButtonWrapper>

          <Header onClick={toggleFooter}>
            {headerText}
            {clipBoardBtn}
          </Header>
        </div>

        <ConditionView active={false}>
          <Article>
            <BookList>{basketBooks}</BookList>
          </Article>
          <Lower onClick={handleRedirect}>결제없이 받아보기</Lower>
        </ConditionView>
      </Dialog>
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ active: boolean }>`
  position: fixed;
  width: 100%;
  top: ${(props) => (props.active ? 0 : "null")};
  bottom: 0;
  left: 0;
  right: 0;
  background-color: gre;
  margin: 0 auto;
  z-index: 10;

  @media (min-width: 420px) {
    width: 500px;
  }
`;

const Dialog = styled("div")`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const BookList = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Button = styled("div")`
  width: 100px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 20px solid grey;
  cursor: pointer;
`;

const ButtonText = styled("img")<{ active: boolean }>`
  cursor: pointer;
  position: absolute;
  margin-top: 5px;
  transform: ${(props) => (props.active ? "rotate(0deg)" : "rotate(180deg)")};
`;

const Header = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  background-color: grey;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 800;
  cursor: pointer;
`;

const ConditionView = styled("div")<{ active: boolean }>`
  overflow: hidden;
  height: ${(props) => (props.active ? "425px" : 0)};
  transition: 0.3s;
`;

const Article = styled("div")`
  background-color: #fff;
  padding: 2rem;
`;

const Lower = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
  background: #000;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 800;
  cursor: pointer;
`;

const ClipBoard = styled("img")`
  right: 18px;
  position: absolute;
`;

export default Footer;
