import React from 'react';
import styled from 'styled-components';

interface Props {
  text?: string;
  name?: string;
  style?: React.CSSProperties;
  checked?: boolean;
  extraContent?: JSX.Element;
  defaultChecked?: boolean;
  handleChecked?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

const CheckBox = (props: Props) => {
  const { text, handleChecked, name, checked, extraContent, defaultChecked, style, ...restProps } = props;

  return (
    <Container {...restProps}>
      <Label style={style}>
        {text}
        <Check
          checked={checked}
          name={name}
          onClick={handleChecked}
          defaultChecked={defaultChecked}
          onChange={() => {}}
        />
        <CheckMark />
      </Label>
      <ExtraContent>{extraContent}</ExtraContent>
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  align-items: flex-end;
`;

const Check = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const CheckMark = styled("span")`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;

  &:after {
    content: "";
    position: absolute;
    display: none;

    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Label = styled("label")`
  display: block;
  position: relative;
  padding-left: 35px;

  padding-top: 4px;
  cursor: pointer;
  font-size: 16px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & ${Check}:checked + ${CheckMark} {
    background-color: #000;
  }

  & ${Check}:checked + ${CheckMark}:after {
    display: block;
  }
`;

const ExtraContent = styled("div")`
  margin-left: 8px;
`;

export default CheckBox;
