import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AddressData } from 'react-daum-postcode';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BookListIcon from '../assets/icons/ico_booklist.png';
import { CardForm } from '../components/CardForm';
import CheckBox from '../components/CheckBox';
import LabelInput from '../components/LabelInput';
import Modal from '../components/Modal';
import MyBook from '../components/MyBook';
import { PostCode } from '../components/PostCode';
import { BasketType } from '../config/types';
import useCheckBox from '../hooks/useCheckBox';
import useInput from '../hooks/useInput';
import useRadio from '../hooks/useRadio';
import lib from '../lib/library';
import OrderStore from '../stores/order';

interface InjectedProps {
  order: OrderStore;
}

interface Props extends InjectedProps {}

const Order = (props: Props) => {
  const DATA: BasketType[] = JSON.parse(sessionStorage.getItem("books")!);

  const { order } = props;

  const history = useHistory();

  const [address1, setAddress] = useState("");

  const [state, onChange, setInput] = useInput({
    customer_name: "",
    customer_phone: "",
    receiver_name: "",
    shipping_phone: "",
    receiveRequest: "",
    address2: "",
    card1: "",
    card2: "",
    card3: "",
    card4: "",
    ExpYear: "",
    ExpMonth: "",
    CardPw: "",
    IDNo: ""
  });

  const [radioState, radioChecked] = useRadio({
    cardType: "Registered"
  });

  const [checkBoxState, onChecked] = useCheckBox({
    orderCheckBox: false
  });

  const {
    customer_name,
    customer_phone,
    receiver_name,
    shipping_phone,
    receiveRequest,
    address2,
    card1,
    card2,
    card3,
    card4,
    ExpYear,
    ExpMonth,
    CardPw,
    IDNo
  } = state;

  const { orderCheckBox } = checkBoxState;

  const handleAddress = useCallback(
    (data: AddressData) => {
      if (data.userSelectedType === "R") {
        setAddress(data.address);
      } else if (data.userSelectedType === "J") {
        setAddress(data.jibunAddress);
      }

      setInput({ name: "address2", value: `(${data.bname})` });
    },
    [setInput]
  );

  const handleChecked = useCallback(
    (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      if (e.currentTarget.name === "orderCheckBox") {
        if (e.currentTarget.checked) {
          setInput({ name: "receiver_name", value: customer_name });
          setInput({ name: "shipping_phone", value: customer_phone });
        } else {
          setInput({ name: "receiver_name", value: "" });
          setInput({ name: "shipping_phone", value: "" });
        }
      }
      onChecked(e);
    },
    [customer_name, customer_phone, onChecked, setInput]
  );

  const validationChk = useCallback((value: string) => {
    const phoneRule = /^[0-9]{10,11}$/; //전화번호 정규식
    if (!value) return "";
    if (!phoneRule.test(value)) return "전화번호 양식을 지켜주세요.";
    else return "";
  }, []);

  const convertTitle = useMemo(() => {
    if (!DATA) return;
    else if (DATA.length === 1) return DATA[0].bookName;
    else return `${DATA[0].bookName} 외 ${DATA.length - 1}권`;
  }, []);

  const myBookList = useMemo(() => {
    return (
      <div>
        {DATA?.map((el, index) => (
          <MyBook key={index} data={el} />
        ))}
      </div>
    );
  }, []);

  const handleSubmit = async () => {
    if (order.userOrderData.card_num) {
      if (radioState.cardType === "New") {
        const params = {
          bookList: JSON.stringify(DATA!),
          buyer_name: customer_name,
          buyer_phone: customer_phone,
          receiver_name: receiver_name,
          receiver_phone: shipping_phone,
          road: address1,
          road_detail: address2,
          receiver_requirement: receiveRequest,
          privae_chk: 1,
          bibly_YN: 0,
          user_id: sessionStorage.getItem("userId")! ? sessionStorage.getItem("userId")! : "0",
          CardNo: `${card1}${card2}${card3}${card4}`,
          ExpMonth,
          ExpYear,
          IDNo,
          CardPw
        };

        const response = await order.orderBooks(params);
        if (response.status === 200) {
          history.push(`/delivery/${response.data.return[1]}?btrType=oc`);
          sessionStorage.removeItem("books");
        }
      } else if (radioState.cardType === "Registered") {
        const params = {
          bookList: JSON.stringify(DATA!),
          buyer_name: customer_name,
          buyer_phone: customer_phone,
          receiver_name: receiver_name,
          receiver_phone: shipping_phone,
          road: address1,
          road_detail: address2,
          receiver_requirement: receiveRequest,
          privae_chk: 1,
          bibly_YN: 0,
          user_id: sessionStorage.getItem("userId")! ? sessionStorage.getItem("userId")! : "0",
          card_id: order.userOrderData.card_id
        };

        const response = await order.orderBooks(params);
        if (response.status === 200) {
          history.push(`/delivery/${response.data.return[1]}?btrType=oc`);
          sessionStorage.removeItem("books");
        }
      }
    } else {
      const params = {
        bookList: JSON.stringify(DATA!),
        buyer_name: customer_name,
        buyer_phone: customer_phone,
        receiver_name: receiver_name,
        receiver_phone: shipping_phone,
        road: address1,
        road_detail: address2,
        receiver_requirement: receiveRequest,
        privae_chk: 1,
        bibly_YN: 0,
        user_id: sessionStorage.getItem("userId")! ? sessionStorage.getItem("userId")! : "0",
        CardNo: `${card1}${card2}${card3}${card4}`,
        ExpMonth,
        ExpYear,
        IDNo,
        CardPw
      };

      const response = await order.orderBooks(params);
      if (response.status === 200) {
        history.push(`/delivery/${response.data.return[1]}?btrType=oc`);
        sessionStorage.removeItem("books");
      }
    }
  };

  const handleBack = useCallback(() => {
    history.push("/");
  }, [history]);

  const formatPhoneNumber = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const formated = e.target.value.replace(/-/g, "");
      setInput({ name: e.target.name, value: formated });
    },
    [setInput]
  );

  useEffect(() => {
    order.fetchData();
  }, [order]);

  useEffect(() => {
    for (const key in order.userOrderData) {
      setInput({ name: key, value: order.userOrderData[key] });
    }

    const address = order.userOrderData["address1"];

    if (order.userOrderData["address1"]) {
      setAddress(address);
    }
  }, [order.userOrderData]);

  const active = useMemo(() => {
    if (order.userOrderData.card_num) {
      if (radioState.cardType === "New") {
        return (
          customer_name &&
          customer_phone &&
          receiver_name &&
          shipping_phone &&
          address1 &&
          address2 &&
          validationChk(customer_phone).length === 0 &&
          validationChk(shipping_phone).length === 0 &&
          lib.cardNumberValidate(card1 + card2 + card3 + card4) &&
          lib.cardDateValidate(ExpYear) &&
          lib.cardDateValidate(ExpMonth) &&
          lib.cardDateValidate(CardPw) &&
          lib.cardPrivacyValidate(IDNo)
        );
      } else {
        return (
          customer_name &&
          customer_phone &&
          receiver_name &&
          shipping_phone &&
          address1 &&
          address2 &&
          validationChk(customer_phone).length === 0 &&
          validationChk(shipping_phone).length === 0
        );
      }
    } else {
      return (
        customer_name &&
        customer_phone &&
        receiver_name &&
        shipping_phone &&
        address1 &&
        address2 &&
        validationChk(customer_phone).length === 0 &&
        validationChk(shipping_phone).length === 0 &&
        lib.cardNumberValidate(card1 + card2 + card3 + card4) &&
        lib.cardDateValidate(ExpYear) &&
        lib.cardDateValidate(ExpMonth) &&
        lib.cardDateValidate(CardPw) &&
        lib.cardPrivacyValidate(IDNo)
      );
    }
  }, [
    customer_name,
    customer_phone,
    receiver_name,
    shipping_phone,
    address1,
    address2,
    validationChk,
    card1,
    card2,
    card3,
    card4,
    ExpYear,
    ExpMonth,
    CardPw,
    IDNo,
    order.userOrderData.card_num,
    radioState.cardType
  ]);

  return (
    <Wrapper>
      <Modal
        opener={
          <MyBookWrapper>
            <img src={BookListIcon} alt="book-list" />
            <span style={{ marginLeft: 12 }}>{convertTitle}</span>
          </MyBookWrapper>
        }
      >
        {myBookList}
      </Modal>
      <Header>주문하는 분</Header>
      <LabelInput name="customer_name" value={customer_name} label="이름" onChange={onChange} />
      <LabelInput
        type="number"
        name="customer_phone"
        value={customer_phone}
        warning={validationChk(customer_phone)}
        label="연락처"
        onChange={onChange}
        onBlur={formatPhoneNumber}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Header>받는 분</Header>
        <CheckBox text="주문자와 동일" checked={orderCheckBox} name="orderCheckBox" handleChecked={handleChecked} />
      </div>
      <LabelInput name="receiver_name" value={receiver_name} label="이름" onChange={onChange} />
      <LabelInput
        type="number"
        name="shipping_phone"
        value={shipping_phone}
        label="연락처"
        warning={validationChk(shipping_phone)}
        onChange={onChange}
        onBlur={formatPhoneNumber}
      />
      <PostCode
        onAddress={handleAddress}
        opener={<LabelInput name="address1" label="주소" value={address1} editable={false} onChange={onChange} />}
      />
      <LabelInput name="address2" label="상세 주소" value={address2} onChange={onChange} />
      <LabelInput name="receiveRequest" value={receiveRequest} label="요청" onChange={onChange} />
      <CardForm
        onChange={onChange}
        onChecked={radioChecked}
        cardNo={order.userOrderData.card_num}
        cardCompany={order.userOrderData.card_company}
        cardType={radioState.cardType}
      />

      <ButtonWrapper>
        <FinishButton active onClick={handleBack}>
          뒤로가기
        </FinishButton>

        <FinishButton disabled={!active} active={active} onClick={handleSubmit}>
          완료
        </FinishButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  padding: 1rem 2rem;
`;

const Header = styled("h2")`
  margin: 50px 0px 24px 0px;
`;

const MyBookWrapper = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 13px;
  font-size: 15px;
  font-weight: bold;
  border: solid 1px #f2f3f5;
  cursor: pointer;
`;

const FinishButton = styled("button")<{ active: boolean }>`
  display: flex;
  background-color: #fff;
  border: none;
  outline: none;
  margin: 0 auto;
  font-weight: 900;
  color: ${(props) => (props.active ? "#000" : "#dadbde")};
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  margin-top: 1rem;
  cursor: ${(props) => (props.active ? "pointer" : "auto")};
`;

const ButtonWrapper = styled("div")`
  display: flex;
  margin: 50px 0px 18px 0px;
`;

export default inject("order")(observer(Order));
