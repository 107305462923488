import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import SessionCheckLayout from '../layouts/SessionCheckLayout';
import ScrollToTop from '../utils/ScrollToTop';
import Best from './Best';
import Category from './Category';
import CategorySearch from './CategorySearch';
import Delivery from './Delivery';
import DetailView from './DetailView';
import FindEmail from './FindEmail';
import FindPassword from './FindPassword';
import FindPasswordByEmail from './FindPasswordByEmail';
import FindPasswordByPhone from './FindPasswordByPhone';
import Login from './Login';
import Main from './Main';
import NotFoundPage from './NotFoundPage';
import Order from './Order';
import Register from './Register';
import ResetPassword from './ResetPassword';
import Search from './Search';
import UsedBook from './Used';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <SessionCheckLayout exact path="/" component={UsedBook} />
          <SessionCheckLayout path="/search/:book_name" component={Search} />
          <SessionCheckLayout path="/order" component={Order} loginRequired />
          <SessionCheckLayout path="/detail/:book_id" component={DetailView} />
          <SessionCheckLayout path="/delivery/:purchase_id" component={Delivery} />
          <SessionCheckLayout path="/billing/:purchase_id" component={Delivery} />
          <SessionCheckLayout path="/category" component={Category} />
          <SessionCheckLayout path="/categorySearch" component={CategorySearch} />
          <SessionCheckLayout path="/best" component={Best} />
          <SessionCheckLayout path="/bpick" component={Main} />
          <SessionCheckLayout path="/login" component={Login} />
          <SessionCheckLayout path="/register" component={Register} />
          <SessionCheckLayout path="/find-email" component={FindEmail} />
          <SessionCheckLayout exact path="/find-password" component={FindPassword} />
          <SessionCheckLayout path="/find-password/phone" component={FindPasswordByPhone} />
          <SessionCheckLayout path="/find-password/email" component={FindPasswordByEmail} />
          <SessionCheckLayout path="/resetPassword" component={ResetPassword} />
          <SessionCheckLayout path="" component={NotFoundPage} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
