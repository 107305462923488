import axios from 'axios';
import { action, flow, observable } from 'mobx';

import apiServerPath from '../config/config';
import { BannerType } from '../config/types';
import UtilStore from './util';

axios.defaults.baseURL = apiServerPath;
axios.defaults.withCredentials = true;

export default class BannerStore {
  static instance: BannerStore | null = null;

  static getInstance() {
    if (BannerStore.instance === null) {
      BannerStore.instance = new BannerStore();
    }
    return BannerStore.instance;
  }

  @observable bannerData: BannerType[] = [];

  utilStore = UtilStore.getInstance();

  @action
  fetchBanner = flow(function* (this: BannerStore) {
    const response = yield axios.get(`/bibly/api?cmd=get_banner_list`);

    this.bannerData = response.data.list;
  });
}
