import axios from 'axios';
import { action, computed, flow, observable } from 'mobx';

import apiServerPath from '../config/config';
import { BestBookType } from '../config/types';
import UtilStore from './util';

axios.defaults.baseURL = apiServerPath;
axios.defaults.withCredentials = true;

export default class UsedStore {
  @observable bestBookList: BestBookType[] = [];
  @observable bestBooks: BestBookType[] = [];
  @observable page = 1;

  utilStore = UtilStore.getInstance();

  @action
  fetchBestBooks = flow(function* (this: UsedStore) {
    if (!this.bestBooks.length) {
      this.utilStore.setFetching(true);
      const response = yield axios.get(`/bibly/api?cmd=bestseller_daily`);
      this.utilStore.setFetching(false);

      if (response.status === 200) {
        this.bestBookList = response.data.list;
        this.bestBooks = this.bestBookList.slice(0, 30);
      }
    }
  });

  @action
  fetchMoreBestBooks = () => {
    const { page } = this;
    this.bestBooks = [...this.bestBooks, ...this.bestBookList.slice(page * 30, (page + 1) * 30)];
    this.page = this.page + 1;
  };

  @computed
  get fetchMore() {
    return this.bestBookList.length !== this.bestBooks.length;
  }
}
