import { inject, observer } from 'mobx-react';
import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

import UtilStore from '../stores/util';

const ToolTip = () => {
  const utilStore = useMemo(() => {
    return UtilStore.getInstance();
  }, []);

  const data = useMemo(() => {
    return utilStore.tooltips;
  }, [utilStore.tooltips]);

  const tooltips = useMemo(() => {
    return data.map((text: string, index: number) => (
      <ToolTipWrapper index={index} key={index}>
        {text}
      </ToolTipWrapper>
    ));
  }, [data]);

  return <>{tooltips}</>;
};

const ToolTipFade = keyframes`
   0% {
    opacity: 0;
  }

  100%{
   opacity: 1;
  }
  
`;

const ToolTipWrapper = styled("div")<{ index: number }>`
  top: 16px;
  position: fixed;
  z-index: 999;
  box-sizing: border-box;
  white-space: nowrap;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  background-color: #000;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  animation: ${ToolTipFade} 0.3s;
  padding: 8px 16px;
  margin-top: ${(props) => props.index * 54}px;
  transform: translateX(-50%);
  left: 50%;
`;

export default inject("util")(observer(ToolTip));
