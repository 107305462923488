import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/Button';
import FooterInfo from '../components/FooterInfo';
import { Header } from '../components/Header';
import { Input } from '../components/Input';
import useInput from '../hooks/useInput';
import useWarning from '../hooks/useWarning';
import lib from '../lib/library';
import UserStore from '../stores/user';

const ResetPassword = () => {
  const [state, onChange] = useInput({ password: "", passwordChk: "" });

  const [warning, setWarning] = useWarning({ password: "" });

  const { password, passwordChk } = state;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const history = useHistory();

  const query = useQuery();

  const TOKEN = query.get("token");

  const userStore = useMemo(() => {
    return UserStore.getInstance();
  }, []);

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    async function authToken() {
      const response = await userStore.authToken(TOKEN!);

      setFetched(true);

      if (!response) {
        history.push("/errorPage");
      }
    }

    authToken();
  }, [TOKEN, history, userStore]);

  const validatePassword = useCallback(() => {
    if (!password) {
      setWarning({ password: "비밀번호를 입력해주세요." });
      return false;
    } else if (!lib.passwordValidate(password)) {
      setWarning({ password: "비밀번호 양식을 확인해주세요." });
      return false;
    } else {
      setWarning({ password: "" });
      return true;
    }
  }, [password, setWarning]);

  const validatePasswordChk = useCallback(() => {
    if (password !== passwordChk) {
      setWarning({ passwordChk: "비밀번호가 일치하지 않습니다." });
      return false;
    } else {
      setWarning({ passwordChk: "" });
      return true;
    }
  }, [password, passwordChk, setWarning]);

  const resetPassword = useCallback(async () => {
    const passwordValidate = validatePassword();
    const passwordChkValidate = validatePasswordChk();

    if (passwordValidate && passwordChkValidate) {
      const response = await userStore.changePasswordByEmail(TOKEN!, password);

      if (response.status === 200) {
        history.push("/login");
      }
    }
  }, [TOKEN, history, password, userStore, validatePassword, validatePasswordChk]);

  return fetched ? (
    <Container>
      <Header />
      <Content>
        <Title>비밀번호 재설정</Title>
        <Description>비밀번호를 재설정해주세요.</Description>
        <StyledInput
          type="password"
          label="새 비밀번호"
          placeholder="4~15자리 영문, 숫자 포함"
          name="password"
          onChange={onChange}
          onBlur={validatePassword}
          warning={warning.password}
          onSubmit={resetPassword}
        />
        <StyledInput
          type="password"
          label="새 비밀번호 확인"
          name="passwordChk"
          onChange={onChange}
          onBlur={validatePasswordChk}
          warning={warning.passwordChk}
          onSubmit={resetPassword}
        />
        <StyledButton value="비밀번호 재설정" variant="dark" onClick={resetPassword} />
      </Content>
      <FooterInfo />
    </Container>
  ) : (
    <></>
  );
};

const Container = styled("div")``;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  max-width: 408px;
  margin: 0 auto;
  min-height: calc(100vh - 58px);
  padding: 64px 0px 128px;

  @media (max-width: 408px) {
    padding: 64px 24px 128px;
  }
`;

const Title = styled("h1")`
  font-size: 32px;
`;

const Description = styled("div")`
  font-size: 14px;
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default ResetPassword;
