import { inject, observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Menu from '../../assets/icons/menu.png';
import UserStore from '../../stores/user';
import { Drawer } from '../Drawer';

const MainDrawer = () => {
  const [opened, setOpened] = useState(false);

  const userStore = useMemo(() => {
    return UserStore.getInstance();
  }, []);

  const userInfo = useMemo(() => {
    return userStore.userInfo;
  }, [userStore.userInfo]);

  const openDrawer = useCallback(() => {
    setOpened(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setOpened(false);
  }, []);

  const headerContent = useMemo(() => {
    return userInfo ? (
      <HeaderContents>
        <Text>
          {userInfo.customer_name}
          <span className="desc">님 반갑습니다.</span>
        </Text>

        <Text>{userInfo.mileage} Points</Text>
      </HeaderContents>
    ) : (
      <>
        <Link to="/login">
          <Button>로그인</Button>
        </Link>

        <Link to="/register">
          <Button>회원가입</Button>
        </Link>
      </>
    );
  }, [userInfo]);

  const handleLogout = useCallback(() => {
    setOpened(false);
    userStore.logout();
  }, [userStore]);

  const logoutBtn = useMemo(() => {
    return userStore.userInfo && <LogoutBtn onClick={handleLogout}>로그아웃</LogoutBtn>;
  }, [handleLogout, userStore.userInfo]);

  return (
    <>
      <MenuIcon src={Menu} alt="menu" onClick={openDrawer} />
      <Drawer opened={opened} onClick={closeDrawer}>
        <Header>
          {headerContent}
          <CloseBtn onClick={closeDrawer} />
        </Header>
        {logoutBtn}
      </Drawer>
    </>
  );
};

const MenuIcon = styled("img")`
  cursor: pointer;
`;

const Header = styled("header")`
  display: flex;
  align-items: center;
  background-color: #000;
  height: 70px;
  padding: 0px 18px;
`;

const HeaderContents = styled("div")`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-right: 18px;
`;

const Text = styled("p")`
  color: #fff;
  font-size: 14px;
  word-break: keep-all;

  .desc {
    font-size: 14px;
    color: #868e96;
  }
`;

const Button = styled("button")`
  color: #fff;
  padding-right: 12px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #000;
  font-size: 14px;

  &:hover {
    opacity: 0.7;
  }

  & + div {
    padding-left: 12px;
    border-left: 1px solid #fff;
  }
`;

const CloseBtn = styled("span")`
  margin-left: auto;

  &::before {
    content: "x";
    color: #fff;
    font-weight: 100;
    font-family: Arial, sans-serif;
    cursor: pointer;
    font-size: 22px;
  }
`;

const LogoutBtn = styled(Button)`
  margin-top: auto;
  height: 56px;
`;

export default inject("user")(observer(MainDrawer));
