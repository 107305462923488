import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button } from '../Button';

interface Props {
  opener?: React.ReactElement<{ onClick: () => void }>;
  opened?: boolean | undefined;
  title?: string;
  subTitle?: string;
  onSuccess: () => void;
}

export const Prompt = (props: Props) => {
  const { opener, title, subTitle, onSuccess } = props;

  const [opened, setOpened] = useState<boolean>(false);

  const showModal = useCallback(() => {
    setOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpened(false);
  }, []);

  const handleSuccess = useCallback(() => {
    onSuccess();
    closeModal();
  }, [closeModal, onSuccess]);

  const clonedOpener = useMemo(() => {
    return (
      opener &&
      React.cloneElement(opener, {
        onClick: showModal
      })
    );
  }, [opener, showModal]);

  return (
    <>
      {clonedOpener}
      <Wrapper opened={opened}>
        <PromptInner>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
          <ButtonWrapper>
            <FooterButton onClick={closeModal} value="아니요" />
            <FooterButton onClick={handleSuccess} value="예" />
          </ButtonWrapper>
        </PromptInner>
      </Wrapper>
    </>
  );
};

const Wrapper = styled("div")<{ opened: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  opacity: ${(props) => (props.opened ? "1" : "0")};
  visibility: ${(props) => (props.opened ? "visible" : "hidden")};
  transition: 0.25s;
`;

const PromptInner = styled("div")`
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 100px;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 32px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

  @media (max-height: 600px) {
    max-height: 500px;
  }
`;

const Title = styled("h3")`
  font-size: 16px;
  font-weight: bold;
  color: #495057;
  margin: 0px;
  flex: 1;
  white-space: pre-line;
`;

const SubTitle = styled("div")`
  font-size: 14px;
  color: #adb5bd;
  margin-top: 12px;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  margin-top: 16px;
`;

const FooterButton = styled(Button)`
  flex: 1;
  margin: 8px 8px 0px;
`;
