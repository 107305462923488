import React from 'react';
import styled from 'styled-components';

import CheckBox from '../../CheckBox';

interface Props {
  onChecked: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

export const ConsentList: React.FC<Props> = (props) => {
  const { onChecked } = props;

  return (
    <Wrapper>
      <StyledCheckBox
        text="이용약관 동의 (필수)"
        name="useAgree"
        handleChecked={onChecked}
        extraContent={
          <StyledLink
            href="https://docs.google.com/document/d/1fKn3gzR862qXfa7CwpzpJcQqgV7sYjW1CxLVBNy5k9U/edit"
            target="_blank"
          >
            [자세히보기]
          </StyledLink>
        }
      />
      <StyledCheckBox
        text="개인정보처리방침 동의 (필수)"
        name="privacyAgree"
        handleChecked={onChecked}
        extraContent={
          <StyledLink
            href="https://docs.google.com/document/d/1lACIw1DvlQnuf6BFrUyrsI38OI9hEbsSDdWuCjZw5es/edit"
            target="_blank"
          >
            [자세히보기]
          </StyledLink>
        }
      />
      <StyledCheckBox
        text="마케팅 정보 수신동의 (선택)"
        name="marketingCheckBox"
        handleChecked={onChecked}
        extraContent={
          <StyledLink
            href="https://docs.google.com/document/d/19r6Rn0KH7_sd5ARSLODV2M8jaEAuj9Upw0lHF7zyuu8/edit"
            target="_blank"
          >
            [자세히보기]
          </StyledLink>
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled("div")``;

const StyledCheckBox = styled(CheckBox)`
  margin-bottom: 12px;
`;

const StyledLink = styled("a")`
  text-decoration: none;
  cursor: pointer;
  color: #000;

  &:hover {
    text-decoration: underline;
  }
`;
