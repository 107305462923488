import React from 'react';
import styled from 'styled-components';

import { CategoryItem } from '../components/CategoryItem';
import { FixedHeader } from '../components/FixedHeader';

const Category = () => {
  return (
    <Container>
      <FixedHeader />
      <CategoryWrapper>
        <CategoryItem content="문학" categoryId="001001017" />
        <CategoryItem content="종교" categoryId="001001021" />
        <CategoryItem content="만화·라이트노벨" categoryId="001001008" />
        <CategoryItem content="어린이" categoryId="001001016" />
        <CategoryItem content="인문" categoryId="001001019" />
        <CategoryItem content="사회·정치" categoryId="001001022" />
        <CategoryItem content="경제·경영" categoryId="001001025" />
        <CategoryItem content="자기계발" categoryId="001001026" />
        <CategoryItem content="유아" categoryId="001001027" />
        <CategoryItem content="역사" categoryId="001001010" />
        <CategoryItem content="건강·취미" categoryId="001001011" />
        <CategoryItem content="예술" categoryId="001001007" />
        <CategoryItem content="국어·외국어" categoryId="001001004" />
        <CategoryItem content="가정·살림" categoryId="001001001" />
        <CategoryItem content="초등참고서" categoryId="001001044" />
        <CategoryItem content="청소년" categoryId="001001005" />
        <CategoryItem content="자연과학" categoryId="001001002" />
        <CategoryItem content="IT" categoryId="001001003" />
        <CategoryItem content="인물" categoryId="001001020" />
        <CategoryItem content="여행" categoryId="001001009" />
      </CategoryWrapper>
    </Container>
  );
};

const Container = styled("div")`
  padding: 0 2rem;
`;

const CategoryWrapper = styled("div")`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
  padding: 24px 0px;
`;

export default Category;
