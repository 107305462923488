import moment from 'moment';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import DeliveryBook from '../../../components/DeliveryBook';
import { CardType, DeliveryBookType } from '../../../config/types';
import lib from '../../../lib/library';
import { ReceiverInfo } from '../ReceiverInfo';

interface Props {
  bookList: DeliveryBookType[];
  info?: any;
  totalPrice?: number;
  totalMileage?: number;
  cardStatus: CardType;
}

interface BuyProps {
  returnBook: DeliveryBookType[];
  purchaseBook: DeliveryBookType[];
  info?: any;
  totalPrice?: number;
  totalMileage?: number;
  cardStatus?: CardType;
}

interface OrderProps {
  bookList: DeliveryBookType[];
  info: any;
}

export const OrderInformation = (props: OrderProps) => {
  const { bookList, info } = props;

  const { receiver_name, shipping_phone, address1, address2, shipping_request_detail } = info;

  const formatedPhone = useMemo(() => {
    return lib.numberAddHypen(shipping_phone);
  }, [shipping_phone]);

  return (
    <Article>
      <Title>받아보는 책들</Title>
      {bookList?.map((el) => {
        return <DeliveryBook key={el.book_id} data={el} />;
      })}

      <Title>받아보는 곳</Title>

      <ReceiverInfo title="신청자" content={receiver_name} />
      <ReceiverInfo title="연락처" content={formatedPhone} />
      <ReceiverInfo title="주소" content={`${address1} ${address2}`} />
      <ReceiverInfo title="요청" content={shipping_request_detail} />
    </Article>
  );
};

export const ReturnInformation = (props: BuyProps) => {
  const { returnBook, purchaseBook, info, cardStatus, totalMileage } = props;

  const { receiver_name, shipping_phone, address1, address2, return_dt } = info;

  const pickUpDate = useMemo(() => {
    return return_dt ? moment(return_dt).format("MM월 DD일 HH시 mm분") : "픽업일 확정시 카카오톡 안내 예정";
  }, [return_dt]);

  const returnBooks = useMemo(() => {
    return returnBook.length
      ? returnBook?.map((el, i) => <DeliveryBook key={i} data={el} />)
      : "모두 구매한 경우 리턴 절차는 진행되지 않습니다";
  }, [returnBook]);

  const purchaseInfo = useMemo(() => {
    return (
      !cardStatus?.card_id && (
        <>
          <Title>결제 방법</Title>
          <ReceiverInfo title="결제방법" content="계좌이체" />
          <ReceiverInfo title="입금 은행" content="우리은행" />
          <ReceiverInfo title="입금 계좌번호" content="라이앤캐쳐스 1006-101-447953" />
          <MoreInfo>현금영수증 발행은 남겨주신 연락처로 자동 처리됩니다.</MoreInfo>
        </>
      )
    );
  }, [cardStatus]);

  const purchaseBooks = useMemo(() => {
    return purchaseBook.length
      ? purchaseBook.map((el, i) => <DeliveryBook showBuyInfo key={i} data={el} />)
      : `구매한 책이 한 권도 없는 경우 소정의 서비스 요금이 청구됩니다. \n(한 권이라도 구입 시 청구되지 않습니다.)`;
  }, [purchaseBook]);

  const price = useMemo(() => {
    const totalPrice = purchaseBook.reduce((prev, next) => prev + next.price * (1 - next.discount_rate), 0);
    return totalPrice ? `${lib.numberCommaFormat(totalPrice + info.used_mileage)}원` : "3,000원";
  }, [purchaseBook, info.used_mileage]);

  const mileage = useMemo(() => {
    return totalMileage ? `${lib.numberCommaFormat(totalMileage)}점` : "0점";
  }, [totalMileage]);

  const pickUpInfo = useMemo(() => {
    return returnBook.length ? (
      <>
        <Title>픽업 진행 정보</Title>
        <ReceiverInfo title="픽업 날짜" content={pickUpDate} />
        <ReceiverInfo title="신청자" content={receiver_name} />
        <ReceiverInfo title="연락처" content={lib.numberAddHypen(shipping_phone)} />
        <ReceiverInfo title="주소" content={`${address1} ${address2}`} />
      </>
    ) : null;
  }, [returnBook, pickUpDate, receiver_name, shipping_phone, address1, address2]);

  return (
    <Article>
      <Title>구매 책들</Title>
      {purchaseBooks}

      <Title>결제 정보</Title>
      <ReceiverInfo title="최종결제금액" content={price} />
      <ReceiverInfo title="적립 마일리지" content={mileage} />
      {purchaseInfo}

      <Title>리턴 책들</Title>
      {returnBooks}
      {pickUpInfo}
    </Article>
  );
};

const Article = styled("div")`
  padding: 0 1.5rem;
  padding-bottom: 1.5rem;
  min-height: 70vh;
  white-space: pre-line;
`;

const Title = styled("h2")`
  padding-top: 20px;
`;

const MoreInfo = styled("p")`
  color: #bbb;
  font-size: 13px;
`;
