import axios from 'axios';
import { action, flow, observable } from 'mobx';

import Axios from '../axios';
import apiServerPath from '../config/config';
import { CancelType, CardType, DeliveryBookType, ProcessType, ReceiverType } from '../config/types';
import UtilStore from './util';

interface ReturnType {
  bookList: string;
  receiver_name: string;
  receiver_phone: string;
  receiver_road: string;
  use_mileage: number;
}

axios.defaults.baseURL = apiServerPath;
axios.defaults.withCredentials = true;

export default class DeliveryStore {
  utilStore = UtilStore.getInstance();

  @observable bookList: DeliveryBookType[] = [];

  @observable receiver: ReceiverType = {
    address1: "",
    address2: "",
    customer_id: 0,
    customer_name: "",
    customer_phone: "",
    invoice_number_return: "",
    invoice_number_send: "",
    payment_yn_1400: 0,
    process_seq: 0,
    receiver_name: "",
    return_yn_1900: 0,
    shipping_phone: "",
    shipping_request_detail: "",
    order_id: 0,
    order_cancel_yn: 0,
    mileage: 0,
    used_mileage: 0
  };

  @observable process: ProcessType[] = [];

  @observable cardStatus: CardType[] = [];

  @action
  fetchDelivery = flow(function* (this: DeliveryStore, purchaseId: string) {
    const response = yield axios.get(`/deliveryapi/${purchaseId}`);

    if (response.status === 200) {
      this.bookList = response.data.bookList;
      this.receiver = response.data.result;
      this.process = response.data.processList;
      this.cardStatus = response.data.cardStatus;
    }
  });

  @action
  returnBook = flow(function* (this: DeliveryStore, data: ReturnType, purchaseId: string | undefined) {
    this.utilStore.setFetching(true);
    const response = yield Axios.post(`/returnBookSuccessApi/${purchaseId}`, data);
    this.utilStore.setFetching(false);
    return response;
  });

  @action
  cancelDelivery = flow(function* (this: DeliveryStore, orderId: number, data: CancelType) {
    const { reason, reason_direct } = data;

    this.utilStore.setFetching(true);
    const response = yield Axios.post(
      `/bbl/api?cmd=order_cancel&order_id=${orderId}&reason=${reason}&reason_direct=${reason_direct}`
    );
    this.utilStore.setFetching(false);
    return response;
  });
}
