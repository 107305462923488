import { observable, action } from "mobx";

export interface User {
  name: string;
  phone: string;
  address: string;
  detailAddress: string;
}

export default class MemberStore {
  public static NAME = "member";

  @observable
  orderer: User | null = null;
  @observable
  receiver: User | null = null;

  @action.bound
  setOrderer(user: User) {
    this.orderer = user;
  }

  @action.bound
  setReceiver(user: User) {
    this.receiver = user;
  }
}
