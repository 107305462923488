import React from 'react';
import styled from 'styled-components';

export const BorderBar = () => <StyledBorderBar>|</StyledBorderBar>;

const StyledBorderBar = styled("span")`
  display: inline-block;
  width: 1px;
  height: 13px;
  text-indent: -999em;
  background: #e4e4e5;
  margin: 0px 4px;
`;
