import React from 'react';
import styled from 'styled-components';

import { BasketType } from '../config/types';
import { Thumbnail } from './Thumbnail';

interface Props {
  data: BasketType;
}

const MyBook = (props: Props) => {
  const { imagePath, bookName, authorName, publisherName } = props.data!;

  return (
    <Wrapper>
      <Thumbnail src={imagePath} />
      <BookDetail>
        <BookTitle>{bookName}</BookTitle>
        <Descriptipn>{authorName}</Descriptipn>
        <Descriptipn>{publisherName}</Descriptipn>
      </BookDetail>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  width: 100%;
  min-height: 132px;

  & + div {
    margin-top: 1.5rem;
  }
`;

const BookDetail = styled("div")`
  padding-left: 1rem;
  flex: 9;
`;

const BookTitle = styled("p")`
  margin: 0;
  padding-top: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  word-break: keep-all;
`;

const Descriptipn = styled("p")`
  color: #999999;
  font-size: 13px;
`;

export default MyBook;
