import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  removeMargin?: boolean;
}

export const FixedHeader: React.FC<Props> = (props) => {
  const { removeMargin } = props;

  const isHome = useMemo(() => {
    return window.location.pathname === "/";
  }, []);

  const isBPick = useMemo(() => {
    return window.location.pathname === "/bpick";
  }, []);

  const isBest = useMemo(() => {
    return window.location.pathname === "/best";
  }, []);

  const isCategory = useMemo(() => {
    return window.location.pathname === "/category";
  }, []);

  return (
    <Wrapper removeMargin={removeMargin}>
      <Item active={isHome} to="/">
        핫딜 25!
      </Item>
      <Item active={isBPick} to="/bpick">
        B-Pick
      </Item>
      <Item active={isBest} to="/best">
        베스트
      </Item>
      <Item active={isCategory} to="/category">
        분야별
      </Item>
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ removeMargin: boolean | undefined }>`
  display: flex;
  justify-content: space-around;
  text-align: center;
  position: sticky;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  z-index: 2;
  font-size: 14px;
  top: 0;
  margin: ${(props) => !props.removeMargin && "0 -24px"};
`;

const Item = styled(Link)<{ active: boolean }>`
  color: #000;
  text-decoration: none;
  cursor: pointer;
  height: 55px;
  line-height: 55px;

  ${(props) => props.active && "border-bottom: 2px solid #000"}
  ${(props) => props.active && "font-weight: 800"}
`;
