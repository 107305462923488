import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import PrevArrow from '../assets/icons/ico_btn_back.png';
import NextArrow from '../assets/icons/ico_btn_next.png';

interface Props {
  children: any;
}

interface WrapperProps {
  page: number;
}

interface ButtonProps {
  visible: boolean | null;
}

const Pagination = (props: Props) => {
  const { children } = props;

  const pagination = useRef<HTMLDivElement>(null);

  const [nextVisible, setNextVisible] = useState<boolean>(true);
  const [page, setPage] = useState(0);

  const handlePrev = () => {
    if (!page) return;
    setPage(page - 1);
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    const { scrollWidth, offsetWidth } = pagination.current!;
    setNextVisible(scrollWidth - offsetWidth > offsetWidth);
  }, [pagination.current?.scrollWidth]);

  return (
    <Wrapper ref={pagination}>
      <Slider page={page}>{children}</Slider>
      <Button visible={page > 0} onClick={handlePrev}>
        <img src={PrevArrow} alt="prev" />
      </Button>
      <Button visible={nextVisible} onClick={handleNext}>
        <img src={NextArrow} alt="next" />
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  position: relative;
  overflow: hidden;
`;

const Slider = styled("div")`
  display: flex;

  transition: transform 0.5s;

  transform: translateX(-${(props: WrapperProps) => props.page * 435}px);

  @media (max-width: 420px) {
    transform: translateX(0);
    overflow-x: scroll;
  }
`;

const Button = styled("div")`
  top: 62px;
  position: absolute;
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  opacity: 0;
  left: 0;
  padding: 16px 4px;
  cursor: pointer;
  transition: 0.15s;
  display: ${(props: ButtonProps) => (props.visible ? "block" : "none")};

  ${Wrapper}:hover & {
    opacity: 0.8;
  }

  & + div {
    left: auto;
    right: 0;
  }

  @media (max-width: 420px) {
    display: none;
  }
`;

export default Pagination;
