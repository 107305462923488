import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Logo from '../../assets/icons/logo.png';
import MainDrawer from '../MainDrawer';

export const Header = () => {
  return (
    <Wrapper>
      <MainDrawer />
      <Link to="/">
        <ImageLogo src={Logo} alt="logo" />
      </Link>
      <Redirect target="_blank" href="https://blog.naver.com/bibly_book/221850328125">
        <Question>?</Question>
      </Redirect>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 48px 0px;

  @media (max-width: 700px) {
    padding: 24px 24px 0px;
  }
`;

const ImageLogo = styled("img")`
  height: 100%;
  cursor: pointer;
  height: 24px;
`;

const Question = styled("div")`
  color: #d4d6d9;
  padding: 3px 8px;
  font-size: 16px;
  border: 1px solid #d4d6d9;
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 8px;
`;

const Redirect = styled("a")`
  text-decoration: none;
`;
