import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import BackBtn from '../../../assets/icons/back.png';
import Logo from '../../../assets/icons/logo.png';
import SearchInput from '../../SearchInput';

export const Header = () => {
  const handleBack = useCallback(() => {
    window.history.back();
  }, []);

  const backBtn = useMemo(() => {
    return window.history.length !== 2 && <ImageBtn src={BackBtn} alt="logo" onClick={handleBack} />;
  }, [handleBack]);

  return (
    <Wrapper>
      <ImageWrapper>
        {backBtn}
        <Link to="/">
          <ImageLogo src={Logo} />
        </Link>
      </ImageWrapper>
      <SearchInput />
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  background-color: #fff;
  padding: 16px 32px;
`;

const ImageWrapper = styled("div")`
  position: relative;
  width: 100%;
  height: 24px;
`;

const ImageBtn = styled("img")`
  height: 100%;
  cursor: pointer;
`;

const ImageLogo = styled("img")`
  height: 100%;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
