import React from "react";
import styled, { keyframes } from "styled-components";

interface Props {
  style?: React.CSSProperties;
  width?: number;
  height?: number;
  loading: boolean;
  children: any;
}

export const Skeleton = (props: Props) => {
  const { width, height, style, loading, children, ...restProps } = props;

  return (
    <>
      <Wrapper style={style} width={width} height={height} loading={loading} {...restProps}>
        <WrapperShow>show!</WrapperShow>
      </Wrapper>
      <Content loading={loading}>{children}</Content>
    </>
  );
};

type WrapperProps = Pick<Props, "width" | "height" | "loading">;

const fadeOut = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
`;

const Wrapper = styled("div")<WrapperProps>`
  display: ${(props) => !props.loading && "none"};
  background-color: #e9ecef;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  animation: ${fadeOut} 1s infinite linear alternate;
  border-radius: 5px;
`;

const Content = styled("div")<{ loading: boolean }>`
  display: ${(props) => props.loading && "none"};
`;

const WrapperShow = styled("div")`
  opacity: 0;
`;

Skeleton.defaultProps = {
  loading: true
};
