import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  OrderInformation,
  ReturnInformation,
} from "../components/Deliver/Information";
import { CancelForm } from "../components/Delivery/CancelForm";
import { Header } from "../components/Delivery/Header";
import PurchaseForm from "../components/Delivery/PurchaseForm";
import FooterInfo from "../components/FooterInfo";
import TextButton from "../components/TextButton";
import { CancelType } from "../config/types";
import DeliveryStore from "../stores/delivery";
import UtilStore from "../stores/util";

type InjectedProps = {
  delivery: DeliveryStore;
  util: UtilStore;
};

interface Props extends InjectedProps {}

const Delivery = (props: Props) => {
  const history = useHistory();

  const { delivery, util } = props;

  const { purchase_id } = useParams();

  const [isBuyForm, setIsBuyForm] = useState(false);

  const bookList = useMemo(() => {
    return delivery.bookList;
  }, [delivery.bookList]);

  useEffect(() => {
    if (!purchase_id) return;
    delivery.fetchDelivery(purchase_id!);
  }, [purchase_id, delivery]);

  const returnBook = useMemo(() => {
    return bookList?.filter((el) => el.return_yn === 1);
  }, [bookList]);

  const shoppingBook = useMemo(() => {
    return bookList?.filter((el) => !el.return_yn);
  }, [bookList]);

  const receiver = useMemo(() => {
    return delivery.receiver;
  }, [delivery.receiver]);

  const processList = useMemo(() => {
    return delivery.process;
  }, [delivery.process]);

  const processSeq = useMemo(() => {
    if (receiver.process_seq === 1400) return 2000;
    else return receiver.process_seq;
  }, [receiver]);

  const cardStatus = useMemo(() => {
    return delivery.cardStatus[0];
  }, [delivery.cardStatus]);

  const totalPrice = useMemo(() => {
    return shoppingBook.reduce((prev, next) => prev + next.price, 0);
  }, [shoppingBook]);

  const totalMileage = useMemo(() => {
    return totalPrice * 0.05;
  }, [totalPrice]);

  const handleCancel = useCallback(
    async (data: CancelType) => {
      const response = await delivery.cancelDelivery(receiver.order_id, data);
      if (response.status === 200) {
        delivery.fetchDelivery(purchase_id!);
        util.addToolTips("주문이 취소되었습니다.");
      }
    },
    [delivery, receiver.order_id, purchase_id, util]
  );

  const orderCancelBtn = useMemo(() => {
    return processSeq < 900 && !receiver.order_cancel_yn ? (
      <CancelForm onCancel={handleCancel} />
    ) : (
      <Description>출고 이전에만 주문취소가 가능해요</Description>
    );
  }, [processSeq, handleCancel, receiver.order_cancel_yn]);

  const isCanceled = useMemo(() => {
    return receiver.order_cancel_yn === 1;
  }, [receiver.order_cancel_yn]);

  useEffect(() => {
    if (window.location.href.includes("billing")) {
      history.push(`/delivery/${purchase_id}`);
    }
  }, [history, purchase_id]);

  const handleBuyForm = useCallback(() => {
    window.scrollTo(0, 0);
    setIsBuyForm(true);
  }, []);

  const purchaseBtn = useMemo(() => {
    return (
      (processSeq === 1100 || processSeq === 1200) && (
        <ButtonWrapper>
          <TextButton
            style={{ marginBottom: 20 }}
            text="구매 결정하기"
            onClick={handleBuyForm}
          />
        </ButtonWrapper>
      )
    );
  }, [processSeq, handleBuyForm]);

  const cancelBuyForm = useCallback(() => {
    window.scrollTo(0, 0);
    setIsBuyForm(false);
  }, []);

  const content = useMemo(() => {
    if (isCanceled) {
      return <OrderInformation bookList={bookList} info={receiver} />;
    } else if (isBuyForm) {
      return (
        <PurchaseForm
          bookList={bookList}
          purchaseId={purchase_id!}
          receiver={receiver}
          cancelBuyForm={cancelBuyForm}
        />
      );
    } else if (processSeq >= 1300) {
      return (
        <ReturnInformation
          returnBook={returnBook}
          purchaseBook={shoppingBook}
          info={receiver}
          cardStatus={cardStatus}
          totalPrice={totalPrice}
          totalMileage={totalMileage}
        />
      );
    } else {
      return (
        <>
          <OrderInformation bookList={bookList} info={receiver} />
          {purchaseBtn}
        </>
      );
    }
  }, [
    processSeq,
    isBuyForm,
    bookList,
    cancelBuyForm,
    cardStatus,
    purchaseBtn,
    purchase_id,
    receiver,
    returnBook,
    shoppingBook,
    totalMileage,
    totalPrice,
    isCanceled,
  ]);

  return (
    <Wrapper>
      <Header
        processSeq={processSeq}
        isBuyForm={isBuyForm}
        processList={processList}
        isCanceled={isCanceled}
        isReturned={!!returnBook.length}
      />
      {content}

      <StyledLink to="/">
        <StyledTextButton text="홈으로 가기" />
      </StyledLink>

      {orderCancelBtn}

      <FooterInfo />
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  max-width: 500px;
`;

const ButtonWrapper = styled("div")`
  padding: 0px 1.5rem 1.5rem 1.5rem;
`;

const Description = styled("p")`
  font-size: 14px;
  color: #adb5bd;
  padding: 0 1.5rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledTextButton = styled(TextButton)`
  margin-bottom: 32px;
`;

export default inject("delivery", "util")(observer(Delivery));
