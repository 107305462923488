import React from "react";
import styled from "styled-components";

type Props = {
  style?: React.CSSProperties;
  title: string;
  content: string | undefined;
  hideBorder?: boolean;
};

export const ReceiverInfo = (props: Props) => {
  const { style, title, content, hideBorder } = props;

  return (
    <Wrapper style={style} hideBorder={hideBorder}>
      <Title>{title}</Title>
      <Content>{content ? content : "없음"}</Content>
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ hideBorder: boolean | undefined }>`
  padding: 14px 0px;
  border-bottom: ${(props) => !props.hideBorder && "1px solid #f2f3f5"};
`;

const Title = styled("span")`
  display: inline-block;
  font-size: 13px;
  color: #666666;
  width: 100px;
`;

const Content = styled("div")`
  font-weight: 500;
  font-size: 16px;
`;
