import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  title?: string;
  content: string;
  style?: React.CSSProperties;
}

const MoreView = (props: Props) => {
  const { title, content, style } = props;

  const moreViewRef = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [more, setMore] = useState<boolean>(false);

  useEffect(() => {
    setMore(false);
  }, [content]);

  const handleMore = useCallback(() => {
    setMore(!more);
  }, [more]);

  const moreViewBtn = useMemo(() => {
    return (
      visible && (
        <MoreButtonWrapper>
          <MoreButton onClick={handleMore}>{more ? "숨기기" : "더보기"}</MoreButton>
        </MoreButtonWrapper>
      )
    );
  }, [visible, more, handleMore]);

  useEffect(() => {
    setVisible(moreViewRef.current!.offsetHeight === 200);
  }, [moreViewRef.current]);

  return (
    <Wrapper style={style}>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
      <Content ref={moreViewRef} dangerouslySetInnerHTML={{ __html: content }} more={more} />
      {moreViewBtn}
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 18px;
  background-color: #fff;
`;

const TitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const Title = styled("div")`
  font-size: 22px;
  font-weight: bold;
`;

const Content = styled("div")<{ more: boolean }>`
  max-height: ${(props) => (props.more ? "" : "200px")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  margin-top: 18px;
  font-size: 14px;
  transition: 0.25s ease-in;
  line-height: 20px;
`;

const MoreButtonWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MoreButton = styled("button")`
  outline: none;
  border: none;
  cursor: pointer;
  color: #ced4da;
  font-size: 15px;
  font-weight: bold;
  padding: 12px;
  background-color: #fff;
`;

export default MoreView;
